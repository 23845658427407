.actionContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 2rem;

  .btn {
    width:100px;
    height: 40px;
    border-radius: 20px ;
    background-image: linear-gradient(
      106deg,
      var(--secondary-400),
      var(--primary-400) 89%
    );

    img{
      width:20px;
      height:20px;

    }

    span{
      font-size: 1.125rem;
      color: var(--neutral-white);
      font-family: "ManropeRegular";
    }
  }
}
