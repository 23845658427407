.subsection {
  background-color: var(--grey-10);
  border-radius: 8px;
  margin-bottom: 0.5rem;
  font-size: 0.95rem;
  line-height: 1.25;
  padding-top: 0.875rem;
  padding-bottom: 1rem;

  .subsectionHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 2rem;
    padding-right: 1rem;
    margin-bottom: 0.5rem;

    .titleDragIconWrapper{
      display: flex;
      justify-content: center;
      align-items: center;

      .dragIcon {
        cursor: grabbing;
        pointer-events: none;
        width: 28px;
        height: 28px;
      }
  
      h4 {
        font-family: "ManropeSemiBold";
        color: var(--dark-blue);
        margin: 0.5rem 0;
        font-size: 1rem;
        font-weight: 500;
      }
    }

    &.dragMenu {
      padding-left: 0.25rem !important;
      cursor: grabbing;
    }

    &.dragging {
      background-color: var(--primary-25);
      border: 1px dotted var(--primary-400);
      box-sizing: border-box;
    }
  }

  .actionBtnsContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;

    .actionBtn {
      width: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 28px;
        height: 28px;
      }
    }
  }

  .detailsContainer{
    padding: 0rem 2rem;

    ul{
      list-style: disc;
      padding-left: 1rem;
    }

    p{
      margin:0px;
      line-height: 1.25rem;
    }
  }
}
