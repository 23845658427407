/* Root-level CSS variables defining color palette and theme */
:root {
  /* Primary colors */
  --primary-25: #f1ecfb;
  /* --primary-very-light: #e7dafc; */
  --primary-50: #d8c1fe;
  --primary-100: #c9a8ff;
  --primary-light: #a372ff;
  --primary-200: #9f6df3;
  --primary-400: #8748f0;
  --primary-dark: #5726a7;
  --primary-700: #573390;
  --primary-800: #362c69;
  --primary-900: #212547;
  --primary-dark-active-sidebar: #8848f037;

  /* Secondary colors */
  --secondary-light: #d8effd;
  --secondary-100: #b1defb;
  --secondary-400: #3badf5;
  --secondary-600: #2f8ac4;
  --dark-blue: #212547;
  --dark: #131629;
  --dark-blue-opacity: #131629ed;

  /* Additional colors */
  --black: #000;
  --black-2: #090909;
  --white: #fff;
  --neutral-white: #fcfcfc;
  --background: #e7eef3;
  --disabled: #dfdfdf;
  --light-grey: #c5c4cc;
  --dark-grey: #5b5b5b;
  --grey-10: #f3f5f7;
  --grey-50: #f7f8f9;
  --grey-100: #f2f2f2;
  --grey-150: #edf0f3;
  --grey-200: #ebeef0;
  --grey-250: #d7dbdf;
  --grey-300: #dde1e5;
  --grey-500: #c4c7cc;
  --grey-600: #b5b8be;
  --grey-650: #a6acb4;
  --grey-700: #9092a3;
  --grey-800: #6f727e;
  --grey-900: #5f6572;
  --grey-1000: #3a4455;
  --red: #dd271a;
  --error: #d82316;
  --warning: #f73c2e;
  --light-red: #f7d3d0;
  --red-100: #efa7a2;
  --message-green: #1fd6b9;
  --message-red: #ed6172;
  --message-yellow: #f8c907;
  --tangerine: #f09b06;
  --very-light-green: #e9fbf8;
  --light-green: #cef3db;
  --green: #09a03c;
  --light-orange: #fce8d0;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
