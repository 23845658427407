.gridRow {
  display: grid;
  grid-template-columns: 25% 75%;
  align-items: center;
  margin: 0.5rem 0;
}

.row {
  display: flex;
  align-items: center;
  height: 100%;
}

.star {
  width: 1.8rem;
  height: 1.8rem;
  margin-right: 1rem;
  object-fit: contain;
}

.hovered {
  opacity: 0.5;
}
