.card {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 16px;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  min-width: 250px;
  min-height: 270px;
  position: relative;
}

/* Header Section */
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

/* Centering the Device Name */
.deviceName {
  font-size: 16px;
  font-weight: 600;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 40px;
  width: 100%;
  text-align: center;
  white-space: nowrap; 
  overflow: hidden;    
  text-overflow: ellipsis; 
  max-width: 14ch;    
}

/* Info and Status Icons on Right */
.iconContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  position: absolute;
  right: 0;
}

/* Info Icon Styling */
.infoIcon {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

/* Online/Offline Status Icon */
.statusIcon {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.online {
  background-color: #4caf50;
}

.offline {
  background-color: #f44336;
}

/* Pending Subscription Content */

.subscriptionDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.statusLabel {
  background-color: #f9d0a1; 
  color: #212547; 
  padding: 5px 10px;
  border-radius: 5px;
  margin-bottom: 12px; 
  margin-top: 20px;
  font-size: 14px; 
  font-weight: normal;
}

.statusIcon {
  width: 50px; 
  height: 50px; 
  margin-bottom: 12px; 
}

.emailDetails {
  width: 100%; 
}

.emailDetails .row {
  display: flex; 
  justify-content: flex-start; 
  margin: 4px 0; 
}

.emailDetails .row .label {
  flex: 0 0 40%; 
  font-size: 14px;
  color: #3a4455;
}

.emailDetails .row .span {
  flex: 0 0 60%; 
  font-size: 13px;
  font-weight: 600;
  color: #212547;
  overflow: hidden;         
  white-space: nowrap;       
  text-overflow: ellipsis;    
}


/* Connected Device Content */
.connectedContent{
  margin-top: 16px;
}

.connectedContent .row {
  display: flex;
  justify-content: flex-start; 
  margin: 8px 0;
}

.connectedContent .row .label {
  flex: 0 0 40%; 
  font-size: 14px;
  color: #3a4455;
}

.connectedContent .row .span {
  flex: 0 0 60%; 
  font-size: 13px;
  font-weight: 600;
  color: #212547;
  /* handle overflow */
  overflow: hidden;         
  white-space: nowrap;       
  text-overflow: ellipsis;    
}


.actionContainer {
  display: flex;
  justify-content: flex-end;
}

.manageBtn {
  background-color: #f1ecfb;
  color: white;
  margin: 3px auto; /* Auto margins will center the button */
  padding: 3px 16px 4px;
  border-radius: 13.5px;
  background-image: none;
  background-color: var(--primary-25);
  color: var(--dark-blue);
  display: flex; 
  white-space: nowrap;
  line-height: 1;
  width: auto;
}


/* Tooltip and Backdrop */
.tooltip {
  position: absolute;
  top: 40px;
  right: 16px;
  background-color: #fff;
  border-radius: 8px;
  padding: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.title {
  font-weight: bold;
}

.backdrop {
  bottom: 100%;
  top: 0;
  margin-left: -10px;
  border-width: 10px;
  width: 100%;
  height: 100%;
  position: absolute;
  border-color: transparent transparent var(--white) transparent;
}


.obfuscated {
  color: transparent;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  user-select: none;
  opacity: 1;
}

.connectedContent .row .email {
  flex: 0 0 60%; 
  font-size: 15px;
  overflow: hidden;         
  white-space: nowrap;       
  text-overflow: ellipsis;    
}

.emailDetails .row .email {
  flex: 0 0 60%; 
  font-size: 15px;
  overflow: hidden;         
  white-space: nowrap;       
  text-overflow: ellipsis;    
}