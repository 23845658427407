.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 6.7px;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.15);
    overflow: hidden;
    position: relative;
    width: 100%; 
    height: 100%; 
  }
  
  .skeleton {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: relative;
  }
  
  .image {
    border-radius: 6.7px;  
    margin-bottom: 1rem;  
    height: 100%; 
    width: 100%;  
    object-fit: cover; 
  }
  
  