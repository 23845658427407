.list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 1.8rem;
  row-gap: 1rem;

  @media (min-width: 1750px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media (max-width: 1536px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 1440px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.section {
  margin-bottom: 2rem;

  .sectionTitle {
    color: var(--primary-400);
    font-family: "ManropeRegular";
    font-size: 20px;
    margin-bottom: 1.2rem;
    line-height: 1.31;
  }
}