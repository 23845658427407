.page {
  height: 100%;
}

.vodList {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 1.5rem;
  row-gap: 1.25rem;

  @media (min-width: 1750px) {
    grid-template-columns: repeat(7, 1fr);
  }

  @media (max-width: 1536px) {
    grid-template-columns: repeat(6, 1fr);
  }

  @media (max-width: 1440px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media (max-width: 1200px) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.itemCard {
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--neutral-white);

  &:hover {
    background-size: 100% 0;
    background-color: var(--neutral-white) !important;
  }

  &:not(:hover) {
    background-size: 100% 100%;
  }
}


.paginationFooter {
  width: 100%;
  margin-top: 20px;
}