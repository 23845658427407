.modal {
  width: 50rem;
  max-height: 43rem;
  min-height: 20rem;
  background-color: var(--background);
  padding: 1.125rem 1.5rem;

  h2 {
    margin-top: 0;
    margin-left: 0;
  }
}

.accountSection {
  background-color: var(--white);
  border-radius: 6px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.15);
  padding: 1.5rem 0;

  .inputsContainer {
    padding: 0 1.5rem;
  }

  .rowPass {
    display: grid;
    grid-template-columns: 90% 7.5%;
    // align-items: center;
  }

  .input {
    grid-template-columns: 7rem 82%;
  }

  .inputPass {
    grid-template-columns: 7rem 82%;
    margin-bottom: 0;
  }

  .inputWidth {
    grid-template-columns: 7rem 80%;
  }

  .editPasswordInput {
    grid-template-columns: 30% 70%;
  }

  .marginBottom {
    margin-bottom: 0.5rem;
  }

  .btnReset {
    justify-self: flex-end;
    align-self: flex-start;
    img {
      width: 34px;
      height: 34px;
    }
  }

  .editPassword {
    border-top: 2px solid var(--primary-25);
    margin-top: 1.5rem;

    h4 {
      margin: 1.5rem 1.5rem 1rem;
      color: var(--primary-dark);
      font-family: "ManropeExtraBold";
    }
  }
}

.permissionsSection {
  margin-top: 2rem;
}

.btnActions {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1.5rem 0 0.5rem;
}

.btnDelete {
  width: 10rem;
  margin-bottom: 2rem;
  background-image: none;
  color: var(--dark-blue);
  background-color: var(--light-red);
  user-select: none;
}

.confModal {
  .btn {
    width: 6.5rem;
  }

  .btnConfirm {
    background-color: var(--red-100);
    color: var(--black-2);
  }
}

.error {
  color: var(--warning);
  font-size: 0.9rem;
}

.center {
  text-align: center;
}

.btnEdit {
  // justify-self: flex-end;
  margin-top: 0.2rem;
  width: 3.5rem;
}

.editSection {
  margin-top: 1rem;
}
