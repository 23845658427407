.layout {
  display: grid;
  grid-template-columns: 14.3rem auto;
  column-gap: 2.5rem;
  row-gap: 1rem;

  h2 {
    margin: 0;
    color: var(--primary-700);
    font-family: "Neutra";
  }

  .menuTitle {
    font-family: "ManropeSemiBold";
    margin: 0.5rem 1rem 0.8rem;
    display: block;
  }

  // .headerContent {
  //   text-align: end;
  // }

  .menuCardContainer {
    font-family: "ManropeMedium";
    white-space: normal;
    overflow-wrap: anywhere;

    .card {
      border-radius: 6px;
      box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.15);
      padding: 0.5rem 0;
      margin-bottom: 1rem;
    }

    .menuItemsList {
      max-height: 20rem;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: var(--grey-500);
        border-radius: 5px;
      }
      &::-webkit-scrollbar-track {
        background-color: var(--grey-100);
        border-radius: 4px;
      }
    }

    .row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem;

      .displayAll {
        font-size: 0.875rem;
        cursor: pointer;

        &:hover {
          color: var(--primary-400);
        }
      }

      .selected {
        color: var(--primary-400);
        font-family: "ManropeBold";
      }

      .number {
        font-size: 0.625rem;
        font-family: "ManropeMedium";
      }

      .actionsContainer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }

      .btn {
        width: 1.25rem;
        height: 1.25rem;
        border-radius: 4px;
        border: 1px solid var(--primary-200);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:not(:first-child) {
          margin-left: 0.5rem;
        }

        &:hover {
          background-image: linear-gradient(
            134deg,
            var(--secondary-400),
            var(--primary-400) 87%
          );
          border: none;
        }

        img {
          width: 10px;
          height: 10px;
          object-fit: contain;
        }
      }

      .btnDisabled {
        background-image: none;
        border: 1px solid var(--grey-500);
        cursor: not-allowed;

        &:hover {
          background-image: none;
          border: 1px solid var(--grey-500);
        }
      }
    }

    .justifyEnd {
      justify-content: flex-end;
    }
  }
}

//   .rightContainer {
//     margin-left: 2.5rem;
//     padding-bottom: 1rem;
//     width: 80%;
//   }
