.card {
  width: 8rem;
  height: 12rem;
  transition: background-size 0.5s;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 6.7px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.15);
  position: relative;

  .nameContainer {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 0.875rem;
    font-family: "ManropeExtraBold";
    text-align: center;
    opacity: 0;
    padding: 1.2rem 0.4rem;

    .rank {
      margin-bottom: 1rem;
    }
  }

  .rankCustomInput {
    align-self: center;
    width: 30%;
  }

  .rankInput {
    input {
      padding: 0.4rem 0.2rem;
      text-align: center;
    }
  }

  .catsContainer {
    text-align: center;
    flex-wrap: wrap;
    width: 8rem;
    height: 4.28rem;
    padding: 0 0.5rem;
    overflow-y: auto;
    overflow-x: hidden;
    opacity: 0;

    &::-webkit-scrollbar {
      width: 7px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--primary-25);
      border-radius: 20px;
    }

    .cat {
      background-color: var(--primary-25);
      border-radius: 4px;
      line-height: 1.8;
      font-size: 0.625rem;
      padding: 0 0.3rem;
      margin: 0 0.2rem;
      display: inline-block;
      white-space: nowrap; /* Prevent category names from wrapping */
      overflow: hidden; /* Hide overflow text */
      text-overflow: ellipsis; /* Show ellipsis for overflow text */
    }
  }



  .actionsContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    vertical-align: middle;
    width: 100%;
    opacity: 0;
    margin-bottom: 1.5rem;
    margin-top: 0.5rem;
    user-select: none;
      img {
        cursor: pointer;
        width: 32px;
        height: 32px;
      }
  }

  &:hover {
    .nameContainer,
    .catsContainer,
    .actionsContainer {
      opacity: 1;
      transition: opacity 1s;
    }
  }

  .loaderContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 52px;

    .loader {
      width: 25px;
      height: 25px;
      border-width: 6px;
    }
  }
}
