.backdrop {
  z-index: 300;
}

.modal {
  z-index: 400;
  width: 33rem;
  min-height: 30rem;

  h2 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .row {
    padding: 0 2rem 1rem 1.5rem;
    // display: grid;
    // grid-template-columns: 55% 17% 23%;
    // column-gap: 1rem;
    // align-items: center;

    .buttonTitleIcon {
      height: 100%;
    }

    .expCard {
      padding: 0.5rem 0;
      top: 2.8rem;
      width: 7rem;
    }

    .option {
      border-radius: 3px;
      font-size: 0.85rem;
      line-height: 1.25;
      font-family: "ManropeRegular";

      label {
        left: 2rem;
      }

      img {
        width: 15px;
      }

      &:hover {
        background-color: var(--primary-25);
      }
    }
  }

  .pickerView {
    background-color: var(--grey-50);
    padding: 1.25rem 1.5rem;
    height: 30rem;

    .pickerCell {
      label {
        width: 90%;
        border: 1px solid var(--primary-100);
        left: 2.5rem;
        border-radius: 8px;
        padding: 0.5rem 1rem;
      }
    }
  }

  .actionContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem 0;
  }

  .loader {
    width: 30px;
    height: 1.8rem;
    border-width: 6px;
  }

  .loaderContainer {
    height: 20rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
