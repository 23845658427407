.modal {
  background-color: var(--background);
  max-height: 95%;
  min-height: 20rem;
  width: 45rem;

  input {
    padding-left: 1.5rem;
  }

  .container {
    padding: 0 1.5rem;

    .section {
      background-color: var(--neutral-white);
      border-radius: 8px;
      box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.15);
      margin-bottom: 2rem;

      h3 {
        font-family: "ManropeSemiBold";
        padding: 1rem 1.5rem;
        font-size: 1rem;
        border-bottom: 1px solid var(--primary-100);
      }

      .contentContainer {
        padding: 1rem;

        .row {
          display: grid;
          grid-template-columns: 30% auto;

          label {
            color: var(--grey-800);
            font-size: 0.85rem;
          }
        }
      }
    }
  }

  .passwordRow {
    display: grid;
    grid-template-columns: 90% 10%;
    align-items: center;
    margin-bottom: 0.5rem;

    .customInput {
      margin-bottom: 0;
      grid-template-columns: 33.5% 67%;
    }

    .btnSubmit {
      justify-self: flex-end;
      img {
        width: 33px;
        height: 33px;
        object-fit: contain;
      }
    }
  }

  .actionContainer {
    text-align: center;
    padding-bottom: 1rem;

    .btnViewMore {
      background-image: none;
      background-color: var(--primary-25);
      color: var(--primary-dark);
      width: 10rem;
      margin-top: 1rem;
    }

    .btnUnlink {
      background-image: none;
      background-color: var(--light-red);
      width: 10rem;
      color: var(--black);
      margin-bottom: 0.5rem;
    }

    .btnSave {
      margin-top: 1rem;
    }
  }

  .noMarginBottom {
    margin-bottom: 0.5rem;
  }

  .marginBottom {
    margin-bottom: 1.5rem !important;
  }

  .marginLeft {
    margin-left: 0.5rem;
  }

  .noHistory {
    text-align: center;
    padding-bottom: 1rem;
    font-size: 0.9rem;
  }
}

.btnConfirm {
  background-color: var(--red-100) !important;
  color: var(--black-2) !important;
}
