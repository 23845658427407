.modal {
  .container {
    padding: 0 1.5rem;

    .langContainer {
      display: grid;
      grid-template-columns: 30% 70%;
    }

    .langs {
      margin-bottom: 1rem;
      grid-column-start: 2;
    }
  }

  .line {
    margin-top: 1rem;
  }

  .actionContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5rem 0;
  }

  .loader {
    width: 30px;
    height: 30px;
    border-width: 6px;
  }
}
