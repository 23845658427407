.page {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .card {
    padding: 1rem;
    border-radius: 8px;
    width: 30rem;

    h2 {
      margin: 0.5rem 0 1.5rem;
      text-align: center;
    }

    p {
      font-size: 0.9rem;
    }

    .span {
      display: block;
      cursor: pointer;
      font-size: 0.85rem;
      color: var(--primary-400);
      border-bottom: 1px solid var(--primary-400);
      width: 3.4rem;
      line-height: 0.9rem;
    }

    .loaderContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 15rem;
    }

    .loader {
      width: 30px;
      height: 1.8rem;
      border-width: 6px;
    }

    .qr {
      width: 10rem;
      height: 10rem;
      object-fit: contain;
    }

    .actionContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 1rem 0;
    }
  }
}
