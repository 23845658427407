.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.actionsContainer {
  display: flex;
  align-items: center;

  .btn {
    margin-left: 1rem;
    img {
      width: 40px;
      height: 40px;
    }
  }
}

.list {
  background-color: var(--neutral-white);
  text-align: center;
  padding: 1rem 0;
  border-radius: 6px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.15);
  overflow-x: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--grey-500);
    border-radius: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: var(--grey-100);
    border-radius: 4px;
  }

  span {
    display: block;
    padding: 0.5rem;
    cursor: pointer;
    border-radius: 60px;
  }

  .selected {
    background-color: var(--primary-25);
    color: var(--primary-400);
  }
}
