.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.125rem;

  h2 {
    margin: 0;
    color: var(--primary-700);
    font-family: "Neutra";
  }
}

// .expInput {
//   height: 42px !important;
// }

.actions {
  display: flex;
  align-items: center;
}

.btn {
  width: 40px;
  height: 40px;
  padding: 0;
  margin-left: 1rem;

  img {
    width: 100%;
    height: 100%;
  }
}

.add {
  border-radius: 17.5px;
  height: 2.3rem;
  width: 6rem;
  font-size: 1rem;
  margin-left: 1rem;
}

.line {
  border-width: 2px;
  border-image-source: linear-gradient(
    177deg,
    var(--secondary-400),
    var(--primary-400) 85%
  );
  width: 0.125rem;
  height: 2.6rem;
  margin-left: 1rem;
}

.rotateImage {
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
