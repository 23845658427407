.expandableInput {
  width: 17rem;
  height: 2.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  border-radius: 29px;
  border-width: 2px;
  background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.93),
      rgba(255, 255, 255, 0.93)
    ),
    linear-gradient(98deg, var(--secondary-400), var(--primary-400) 89%) !important;
  margin-left: 1rem;
  // opacity: 0;
  // visibility: hidden;
  // transform: translateX(100%);
  // transition: opacity 0.3s, visibility 0.3s, transform 0.3s;

  input {
    margin-left: 1rem;
    background: none;
    outline: none;
    border: none;
    color: var(--dark-blue);
    font-family: "ManropeRegular";
    width: 100%;
    height: 100%;

    &::-webkit-input-placeholder {
      color: var(--light-grey);
    }
  }
}

.actionsContainer {
  display: flex;
  align-items: center;
  height: 100%;
  padding-right: 0.5rem;
}

.btnConfirm {
  img {
    width: 17.5px;
    height: 17.5px;
  }
}

.btnCancel {
  img {
    width: 17.5px;
    height: 17.5px;
  }
}
