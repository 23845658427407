// pagination
.pagination-container {
    display: flex;
    justify-content: center; 
    align-items: center; 
}

.pagination-button {
    margin: 0 5px; 
    padding: 5px 10px;
    background-color: transparent; 
    color: #000;
    border: none;
    cursor: pointer; 
    transition: border 0.3s ease, color 0.3s ease; 

&.active {
    border: 2px solid transparent;
    background-color: transparent; 
    color: #6200ea; 
    border-image-source: linear-gradient(142deg, #3badf5, #8748f0 87%); 
    border-image-slice: 1; 
    
    border-radius: 20px;
}

&:hover {
    color: #6200ea; 
}

&:focus {
    outline: none; 
}
}


.arrow-button {
    padding: 5px; 
    background-color: transparent; 
    border: none; 
    cursor: pointer; 
    color: #6200ea; 
    display: flex;
    align-items: center; 
    justify-content: center;
    height: 40px; 
    margin-right: 5px; 

&:hover {
    color: #3badf5; 
}

&:focus {
    outline: none; 
}
}

.selectContainer {
    display: flex;
    align-items: center; 
    margin-left: 16px;
}

.rowsPerPageText {
    font-size: 12px;
    margin-right: 8px; 
    color: "#3a4455";
}

.select {
    width: 66px;
    height: 34px;
    color: "#212547";
}

