.langContainer {
  position: relative;
  padding: 14.5px 0;
  border-image-source: linear-gradient(to left, #bf9afb, #99b9fd 60%);
  border-image-slice: 1;
  border-bottom: 2px solid;
  color: var(--grey-800);
}

.disabled {
  border-image-source: none;
  border-bottom: 2px solid var(--grey-500);
}

.list {
  display: flex;

  span {
    cursor: pointer;
    margin: 0 0.5rem;
    user-select: none;
    flex-shrink: 0;
    width: 3.6rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 0.75rem;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.paddingRight {
  padding-right: 1rem;
}

.paddingLeft {
  padding-left: 1rem;
}

.imgContainer {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--neutral-white);
  color: var(--dark-grey);
  border-radius: 50%;
  border: solid 1px var(--primary-400);
  outline: none;
  cursor: pointer;
  z-index: 1;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;

  img {
    width: 10px;
    height: 10px;
  }
}

.left {
  left: 0.2rem;

  img {
    scale: -1;
    margin-top: 0.1rem;
  }
}

.right {
  right: 0.2rem;
}

.active {
  color: var(--primary-400);
  font-family: "ManropeExtraBold";
}
