.btn {
  background-color: var(--primary-25);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  font-size: 0.85rem;
  cursor: pointer;
  user-select: none;
  line-height: 1.25;
  font-family: "ManropeSemiBold";

  img {
    width: 10px;
    height: 10px;
  }
}

.left {
  flex-direction: row-reverse;

  .img {
    margin-left: 0.5rem;
  }
}

.right {
  .img {
    margin-right: 0.5rem;
  }
}

.disabled {
  cursor: default !important;
  background-image: none !important;
  background-color: var(--grey-500) !important;
  color: var(--white) !important;
}
