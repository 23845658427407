.modal {
  width: 50rem;

  .gridContainer {
    padding: 0 1.5rem 0 0.5rem;
    display: grid;
    grid-template-columns: 25% 75%;
    align-items: center;
  }

  .container {
    padding: 0 1.5rem;
  }

  .langContainer {
    grid-column-start: 2;
    margin-bottom: 1.5rem;
  }

  .langList {
    padding-top: 0;
  }

  .uploadContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      font-size: 0.625rem;
      line-height: 1.4;
      color: var(--grey-1000);
      margin: 0 2.7rem;
      text-align: center;
    }

    .fileContainer {
      position: relative;
      width: 4.5rem;
      height: fit-content;
      margin-bottom: 1rem;
      justify-self: center;

      div {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .fileInput {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }

    .icon {
      width: 4.5rem;
      height: 4.5rem;
      border-radius: 8px;
      background-color: var(--dark-blue);

      img {
        object-fit: contain;
      }

      .img {
        width: 3rem;
      }
    }
  }

  .text {
    display: block;
    color: var(--grey-1000);
    line-height: 1.43;
    font-size: 0.95rem;
    margin-bottom: 0.5rem;
  }

  .iconsContainer {
    margin-top: 1rem;
    span {
      margin-left: 1.5rem;
    }

    .iconList {
      display: grid;
      grid-template-columns: repeat(9, 1fr);
      column-gap: 1rem;
      row-gap: 1rem;
      padding: 1.5rem;
    }

    .iconItem {
      height: 70px;
      border-radius: 8px;
      box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.15);
      background-color: var(--dark-blue);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      img {
        width: 57px;
        height: 40px;
      }
    }
  }

  .themesContainer {
    padding: 1.5rem;

    .themeList {
      margin-top: 0.5rem;
      background-color: var(--grey-10);
      border-radius: 8px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 0.6rem;
      column-gap: 1rem;
      padding: 1.2rem;
      max-height: 12.2rem;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: var(--grey-600);
        border-radius: 4px;
      }
      &::-webkit-scrollbar-track {
        background-color: var(--grey-10);
        border-radius: 4px;
      }

      .catItem {
        display: grid;
        grid-template-columns: 15% 10% 80%;
        align-items: center;

        img {
          width: 35px;
          height: 35px;
          border-radius: 3px;
          background-color: var(--dark-blue);
        }
      }
    }
  }

  .actionContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 0;
  }

  .loader {
    width: 30px;
    height: 1.8rem;
    border-width: 6px;
  }
}
