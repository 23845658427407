.modal {
  width: 50rem;
  min-height: 30rem;

  .container {
    padding: 0 1.5rem;
    min-height: 20rem;

    .pickerView {
      margin-top: 1rem;
      max-height: 20rem;
      overflow-y: auto;
    }

    .pickerCell {
      label {
        left: 1.5rem;
      }
    }
  }

  .line {
    margin-top: 1rem;
  }

  .actionContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap:1rem;
    padding: 1.5rem 0;

    button {
      height: 30px;
    }
    .btnCancel {
      background-image: none;
      background-color: transparent;
      border: 1px solid var(--grey-650);
      color: var(--grey-1000);
    }
  }

  .loader {
    width: 30px;
    height: 30px;
    border-width: 6px;
  }
}

.btnConfirm {
  background-image: linear-gradient(
    93deg,
    var(--secondary-400),
    var(--primary-400) 89%
  ) !important;
  background-color: transparent;
}
