.modal {
  .rowsList {
    padding: 0 1rem;
    user-select: none;

    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .actionsContainer {
      display: flex;
      align-items: center;
    }

    .selectedRow {
      background-color: var(--primary-25);
      border-radius: 4px;
    }

    .arrowBottom {
      transform: rotate(180deg);
    }
  }

  .actionContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5rem 0;
  }

  .loader {
    width: 30px;
    border-width: 6px;
    height: 1.8rem;
  }
}
