.section {
  padding: 0 2rem;
  border-right: 1px dashed var(--grey-500);
  user-select: none;

  h2 {
    margin: 1.5rem 0 0.8rem;
  }

  .noContent {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 90%;
  }

  .selectAllPickerViewContainer {
    border: solid 1px var(--grey-300);
    border-radius: 8px;
    margin-top: 1rem;
    overflow: hidden;
    height: 26rem;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 5px;
      height: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--grey-100);
      border-radius: 4px;
    }
    &::-webkit-scrollbar-track {
      background-color: var(--neutral-white);
      border-radius: 4px;
    }

    .selectAllRow {
      background-color: var(--grey-300);
      padding: 0.7rem;
      display: flex;
      align-items: center;
      height: 2.5rem;

      label {
        line-height: 1.25;
        font-size: 0.9rem;
        margin-left: 1rem;
      }

      img {
        width: 20px;
      }
    }

    .selectAllChecked {
      background-color: var(--primary-400);
      color: var(--neutral-white);
    }
  }
}

.groupedPickerViewContainer {
  padding: 0.5rem 1rem;
}
