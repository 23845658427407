
.skeletonRow {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 1rem; 
  }
  
  .skeletonItem {
    margin-right: 5rem; 
  }
  
  .skeletonItemSearch {
    margin-right: 1rem; 
  }
  
  .skeletonContainer {
    display: flex;
    width: 100%;
  }
  
  .leftSide {
    width: 20%; 
    padding: 0 1rem;  
  }
  
  .rightSide {
    width: 75%;  
    padding: 0 1rem; 
  }
  
  .gridContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 2rem;  
  }
  
  .gridContainer > div {
    display: contents;
    justify-content: center;
    align-items: center;
  }
  
  .paginationSkeleton {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
  
    .paginationItem {
      background-color: rgba(0, 0, 0, 0.1); 
      border-radius: 10%;
    }
  }
  