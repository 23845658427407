.modal {
  h2 {
    margin-bottom: 0.5rem;
  }

  .emirateContainer {
    padding: 0 1.5rem 1.5rem;

    .inputsContainer {
      display: grid;
      grid-template-columns: 73% 23%;
      column-gap: 1.5rem;
      row-gap: 1.5rem;
      margin-top: 1.5rem;

      .pickerView {
        margin: 0.7rem 0.3rem;
        padding: 0 0.2rem;
        max-height: 7.5rem;
        overflow-y: auto;
      }

      .pickerCell {
        img {
          left: 0.1rem;
        }

        label {
          left: 2rem;
        }

        &:hover {
          background-color: var(--primary-25);
          border-radius: 4px;
        }
      }

      .noResultContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1.5rem 0;

        span {
          display: block;
          color: var(--grey-700);
          margin: 1rem 0;
        }

        .btn {
          width: fit-content;
          padding-left: 1rem;
          padding-right: 1rem;
        }
      }

      .rankInput {
        grid-template-columns: 50% 50%;
        margin-bottom: 0;

        label {
          color: var(--grey-1000);
        }

        input {
          text-align: center;
          padding-left: 0.5rem;
          padding-right: 0.5rem;
        }
      }

      .townNameInputContainer {
        margin-bottom: 0;
        label {
          color: var(--grey-1000);
        }
      }

      .arrows {
        margin-right: 0;
        button {
          font-size: 0.5rem;
        }
      }
    }
  }

  .townsContainer {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .btnAddTown {
      width: fit-content;
      padding: 0.3rem 1.2rem;
      border-radius: 17.5px;
      font-size: 1rem;

      img {
        width: 15px;
        height: 15px;
      }
    }

    .droppableContainer {
      width: 100%;
      max-height: 18.7rem;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: var(--grey-250);
        border-radius: 4px;
      }
      &::-webkit-scrollbar-track {
        background-color: var(--grey-10);
        border-radius: 4px;
      }
    }

    .marginBottom {
      margin-bottom: 1.5rem;
    }

    .itemsContainer {
      padding-top: 0.5rem;
      display: flex;
      flex-direction: column;
    }

    .draggableItemContainer {
      margin-bottom: 0.5rem;
      width: 98%;
    }

    .draggableItem {
      padding: 5px 20px;
    }

    .dragging {
      height: 33.2px;
    }
  }

  .actionsContainer {
    display: flex;
    align-self: center;
    justify-content: center;
    padding: 1.5rem 0;

    .btnCancel {
      margin-right: 1rem;
      background-image: none;
      background-color: transparent;
      color: var(--grey-1000);
      border: 1px solid var(--grey-900);
    }
  }
}
