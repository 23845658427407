.btn {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  width: 2rem;
  color: var(--light-grey);
  font-size: 1rem;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  img{
    object-fit: contain;
  }
}
