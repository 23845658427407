//row of the menu card layout
.cardRowCell {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1rem;
  cursor: pointer;
}

.cardRowCellName {
  font-size: 0.875rem;

  &:hover,
  &:active {
    color: var(--primary-100);
  }
}

.cardRowCellCount {
  color: var(--grey-800);
  font-size: 0.75rem;
  font-family: "ManropeRegular";
}

.active {
  color: var(--primary-400);
  font-family: "ManropeBold";
}
