.table {
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.15);
  background-color: var(--neutral-white);

  .thContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: var(--primary-25);
    padding: 1rem 0;

    img {
      width: 24px;
      height: 24px;
    }

    .thTitle {
      font-family: "ManropeSemiBold";
      margin-right: 0.5rem;
    }
  }

  .defaultCursor {
    cursor: default;
  }

  .tbody {
    margin: 0 0.5rem 0.5rem;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--grey-500);
      border-radius: 4px;
    }
    &::-webkit-scrollbar-track {
      background-color: var(--grey-10);
      border-radius: 4px;
    }

    .col {
      padding: 1rem 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .emiratesColumn {
      justify-content: flex-start;
      padding-left: 1.2rem;

      img {
        margin-right: 0.8rem;
        width: 12px;
        height: 12px;
      }
    }
  }

  .gridRow {
    display: grid;
    border-radius: 8px;
  }

  .actionsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    user-select: none;

    img {
      width: 35px;
      height: 35px;
    }

    .btnEdit {
      margin-right: 1rem;
    }
  }
}

.confModal {
  p {
    color: var(--grey-1000);
    margin-bottom: 0;
  }

  .span {
    font-size: 0.9rem;
    color: var(--grey-1000);
  }

  .modalSpan {
    font-size: 0.9rem;
    display: block;
    margin-bottom: 0.5rem;
    color: var(--grey-1000);
  }
}

.loaderContainer {
  height: 20rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .loader {
    width: 30px;
    height: 1.8rem;
    border-width: 6px;
  }
}
