.editSection {
  border-top: 1px solid var(--primary-100);

  h4 {
    padding: 0 1.5rem;
    color: var(--primary-dark);
  }
}

.inputContainer {
  margin: 0 1.5rem;
}
