.modal {
  width: 40rem;
  max-height: 50rem;

  .langContainer {
    padding: 0 1.5rem;
  }

  .inputsContainer {
    padding: 1.5rem;
    padding-bottom: 0rem;

    .customInput {
      background-color: white;

      label {
        font-size: 1rem !important;
        color: var(--grey-1000);
      }

      div{
        height: 40px !important;
        input {
          font-size: 16px;
          color: var(--primary-900);
        }
      }
    
      .customArrows, button {
        background-color: white !important;
      }

      span {
        font-size: 0.8rem;
        color: var(--grey-1000);
        margin-top:0.5rem;
      }
    }
  }

  .pickerViewContainer {
    display: grid;
    grid-template-columns: 30% 70%;
    margin-bottom: 1.5rem;

    .pickerView {
      grid-column-start: 2;
    }
    
    .pickerCell {
      label {
        left: 1.5rem;
      }
    }
  }

  

  .btnActions {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 1.5rem 0;

    button {
      height: 35px;
      width: 100px;
      font-size: 1.125rem;
    }

    .btnCancel {
      border: solid 1px var(--grey-900);
      background-image: none;
      background-color: transparent;
      color: var(--grey-900);
    }
  }
}
