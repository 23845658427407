.button {
  display: inline-flex;  
  align-items: center;    
  justify-content: center; 
  gap: 0.5rem;        

  width: 5rem;           
  height: 1.8rem;
  padding: 0.5rem 1rem;   
  background-image: linear-gradient(
    106deg,
    var(--secondary-400),
    var(--primary-400) 89%
  );
  border: 0;
  border-radius: 20px;
  color: var(--white);
  cursor: pointer;
  outline: none;
  font-family: "ManropeRegular";
  white-space: nowrap;   
}

.icon {
  width: 14px;    
  height: 14px;
  display: block;         
}

.disabled {
  background-image: none;
  background-color: var(--grey-300) !important;
  color: var(--grey-800) !important;
  cursor: default;
}
