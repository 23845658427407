.section {
  min-width: fit-content;

  .list {
    display: flex;
    flex-wrap: wrap;
    margin: 1rem 1.5rem 0;
  }

  .container {
    .title {
      font-family: "ManropeSemiBold";
    }

    ul {
      list-style: disc;
      margin-left: 1.2rem;
      // margin-top: 0.2rem;
    }
  }
}

.expMenuContainer {
  padding: 20px;
}

.exp {
  span {
    font-family: "ManropeExtraBold";
  }
}

.sectionTitle {
  color: var(--primary-400);
  font-family: "ManropeMedium";
  font-weight: 500;
  font-size: 20px;
  margin-top: 0;
}

.row {
  display: grid;
  grid-template-columns: 15% 85%;
  align-items: center;
}

.subField {
  grid-column-start: 2;
  display: grid;
  grid-template-columns: 15% 80%;
}

.center {
  width: 100%;
  height: 85%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  span {
    margin-top: 1rem;
  }
}
