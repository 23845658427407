.tr {
  background-color: var(--neutral-white);
  width: 100%;
  cursor: pointer;

  .cellContent {
    display: flex;
    align-items: center;
    width: 100%;

    .content{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .icon {
      margin-right: 0.5rem;
      width: 20px;
      height: 20px;
      object-fit: contain;
    }

    .logo {
      width: 144px;
      height: 30px;
      object-fit: contain;
      margin-right: 1.2rem;
    }

    span{
      font-size: 0.95rem;
    }
  }
}

.bgGrey {
  background-color: var(--grey-100);
}

.obfuscated {
  color: transparent;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  user-select: none;
}

.obfuscated img {
  opacity: 1;
}
