.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.125rem;

  h2 {
    margin: 0;
    color: var(--primary-700);
    font-family: "Neutra";
  }

  .pageTitleContainer {
    display: flex;
    align-items: center;

    span {
      color: var(--grey-800);
      cursor: pointer;
    }

    .arrow {
      margin: 0 0.5rem;
    }
  }

  .searchSortContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .expBtn {
      // min-width: 7rem;
      max-width: 10.5rem;
      background-color: var(--neutral-white);
      border: solid 1px var(--grey-300);
      border-radius: 8px;
      height: 2.5rem;
      font-family: "ManropeRegular";
      justify-content: space-between;
      padding: 0 0.7rem;

      span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .expCard {
      width: 14rem;
      top: 2.6rem;
      left: 0;
      box-shadow: none;
      border: solid 1px var(--grey-500);
    }

    .pickerView {
      padding: 0.7rem;

      label {
        left: 2.5rem;
      }

      img {
        width: 18px;
      }
    }

    .searchInput {
      width: 20rem;
      border: 1px solid var(--grey-600);
    }
  }
}
