.backdrop {
  background-color: var(--dark-blue);
  opacity: 0.55;
  z-index: 100;
}

.modalOverlay {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  width: 40rem;
  position: fixed;
  z-index: 200;
  transition: opacity 0.3s, transform 1s;
  opacity: 0;
  transform: translateY(-100%);
  // max-height: 40rem;
  overflow-y: scroll;
  scrollbar-width: none;
  * {
    -ms-overflow-style: none;
  }
  &::-webkit-scrollbar {
    display: none;
  }

  h2 {
    margin: 1.5rem;
    color: var(--primary-700);
    font-family: "Neutra";
  }
}

.btnClose {
  position: absolute;
  top: 1.2rem;
  right: 0.8rem;
  color: var(--dark-blue);

  img {
    width: 16px;
    height: 16px;
  }
}
