.mainHeader {
  width: 100%;
  padding: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.info {
  display: flex;
  align-items: center;

  li:not(:last-child) {
    margin-right: 0.5rem;
  }

  .dot {
    width: 0.375rem;
    height: 0.375rem;
    border-radius: 50%;
    margin: 0 1rem;
    background-image: linear-gradient(134deg, var(--secondary-400), var(--primary-400) 87%);
  }

  .value {
    color: var(--primary-400);
    font-family: "ManropeBold";
  }
}


// @media (max-width: 1300px) {
//   .terminalInfo {
//     width: 65%;
//     justify-content: flex-start;
//   }

//   .userInfo {
//     width: 35%;
//   }
// }

// @media (max-width: 1090px) {
//   .mainHeader {
//     padding: 1rem;
//     font-size: 0.9rem;
//   }
// }

// @media (max-width: 1000px) {
//   .terminalInfo {
//     flex-direction: column;
//     align-items: flex-start;
//     width: 50%;

//     .dot {
//       margin: 0 4rem;
//     }
//   }

//   .userInfo {
//     width: 50%;
//   }
// }
