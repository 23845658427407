.btn {
  position: relative;
}

.expCardBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 420;
  cursor: default;
}

.expCard {
  position: absolute;
  border-radius: 8px;
  box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.2);
  z-index: 450;
  overflow: hidden;
  background-color: var(--neutral-white);
}
