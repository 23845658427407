.container {
  margin-top: 4rem;
}

.header {
  width: 100%;
  background-color: var(--primary-800);
  position: absolute;
  top: 0;
  left: 0;
  padding: 1.5rem 2.5rem 0 10.5rem; //10.5 = 9rem navbar + 1.5rem padding
  z-index: 2;

  .title {
    display: flex;
    align-items: center;

    span {
      color: var(--grey-500);
      font-size: 0.9rem;
      cursor: pointer;
    }

    .char {
      margin: 0 0.5rem;
      font-size: 1rem;
    }

    h2 {
      color: var(--neutral-white);
      line-height: 1.27;
      font-family: "Neutra";
    }
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .customSelect {
      margin-top: 0.55rem;
      width: 25rem;

      select {
        background-color: transparent;
        border-color: var(--grey-800);
        background-image: url("../../../assets/img/icons/arrow-forward-light.png");
        color: var(--neutral-white);
        padding-left: 1.5rem;

        &:focus,
        &:active {
          background-image: url("../../../assets/img/icons/arrow-bottom-light.png");
        }

        option {
          color: var(--dark-blue);
          padding: 0.5rem;
        }
      }
    }

    .info {
      color: var(--neutral-white);
      display: flex;
      align-items: flex-end;
      font-size: 0.75rem;

      .offersInfo {
        display: flex;
        align-items: center;
      }

      .verticalLine {
        width: 1px;
        height: 18px;
        margin: 0 7.5px;
        border: solid 1.2px var(--neutral-white);
      }

      .bullet {
        width: 5px;
        height: 5px;
        margin: 0 0.5rem;
      }
    }
  }
}