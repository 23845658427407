.modal {
  width: 40rem !important;

  .row {
    display: grid;
    grid-template-columns: 30% 40% 30%;
    margin-bottom: 1rem;
    padding: 0 1.5rem;

    label {
      color: var(--grey-800);
      font-size: 0.9rem;
    }

    input:disabled {
      border: none;
      background-color: transparent;
      color: var(--dark-blue);
    }
  }

  .btnEdit {
    justify-self: flex-end;
    width: 3.5rem;
  }

  .btnActions {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
  }
}

.error {
  color: var(--warning);
  text-align: center;
  font-size: 0.9rem;
}

.pickerCell {
  label {
    left: 1.5rem !important;
  }
}

.googleAuthContainer {
  display: grid;
  grid-template-columns: 90% 10%;
  text-align: center;
  margin: 1rem 0;
  padding-left: 1.5rem;

  .googleAuthLogo {
    width: 1.5rem;
    height: 1.5rem;
    object-fit: contain;
  }
}

.qrCodeContainer {
  display: flex;
  padding: 0 1.5rem 1.5rem;
  justify-content: center;

  .qrImage {
    width: 10rem;
    height: 10rem;
    object-fit: contain;
  }
}
