.popup {
  width: 15rem;
  border-radius: 12px;
  border-bottom-left-radius: 0;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--white);
  z-index: 500;
  padding: 1rem;
  position: absolute;
  bottom: 4rem;
  left: 8rem;

  @media (max-width: 1110px) {
    left: 6rem;
  }
}

.column {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.6rem;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.username {
  font-size: 0.875rem;
  color: var(--primary-400);
  cursor: pointer;
}

.userRole {
  font-family: "ManropeSemiBold";
  font-size: 0.75rem;
  line-height: 1.5;
  margin-top: 0.2rem;
}

.logout {
  font-size: 0.75rem;
  line-height: 1.33;
  color: var(--red);
  text-decoration: underline;
  cursor: pointer;
}

.connexion {
  font-size: 0.625rem;
  line-height: 1.4;
  color: var(--grey-700);
}
