.containerClassName {
  border-radius: 8px;

  span {
    font-family: "ManropeExtraBold";
  }
}

.menuContainerClassName {
  border-color: var(--grey-300);
  margin: 0 1.5rem;
  padding: 1rem 0;
}

.contentList {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  column-gap: 1.5rem;
  row-gap: 1rem;
  max-height: 25.5rem;
  overflow-y: auto;
  padding-bottom: 0.5rem;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--grey-500);
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: var(--grey-100);
    border-radius: 4px;
  }
  @media (min-width: 1750px) {
    grid-template-columns: repeat(8, 1fr);
  }

  @media (max-width: 1536px) {
    grid-template-columns: repeat(6, 1fr);
  }

  @media (max-width: 1440px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media (max-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.itemCard {
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--neutral-white);

  &:hover {
    background-size: 100% 0;
    background-color: var(--neutral-white) !important;
  }

  &:not(:hover) {
    background-size: 100% 100%;
  }
}