.container {
  width: 100%;
  height: 25rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.noItems {
  font-size: 0.875rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .title {
    font-family: "NeutraBold";
    font-size: 1.75rem;
  }

  .textContainer {
    margin-top: 1rem;
    text-align: center;
    line-height: 1.25;
    color: var(--grey-1000);
    p {
      margin: 0;
    }
  }

  .addLink {
    margin-top: 0.5rem;
    margin-left: 0.5rem;
    text-decoration: underline;
    color: var(--primary-400);
    cursor: pointer;
  }
}
