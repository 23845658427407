.customInputContainer {
  label {
    color: var(--grey-800);
    font-size: 0.85rem;
  }

  .span {
    color: var(--grey-800);
    font-size: 0.7rem;
    grid-column-start: 2;
    margin-top: 0.3rem;
  }
}

.row {
  display: grid !important;
  grid-template-columns: 30% 70%;
  margin-bottom: 1.5rem;
  align-items: center;
}

.customInput {
  width: 100%;
  border-radius: 6px;
  border: 0.5px solid var(--grey-800);
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  .inputWithoutIcon {
    width: 100%;
  }

  input {
    width: 100%;
    border: none;
    outline: none;
    padding: 0.4rem 0.5rem;
    padding-right: 0;
    color: var(--dark-blue);
    font-family: "ManropeRegular";

    &::-webkit-input-placeholder {
      color: var(--light-grey);
    }

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 50px white inset;
    }

    // hide input arrows if number
   /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    } 
    /* Firefox */
    &[type="number"] {
      appearance: textfield;
      -moz-appearance: textfield;
    }
  }

  .customArrows {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
  }

  .incrementButton,
  .decrementButton {
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 0.7rem;
    line-height: 1;
    width:30px;
    height:10px;
    color: var(--grey-800);
  }

  .incrementButton {
    margin-bottom: 0.2rem;
  }
}

.focused {
  border-width: 2px;
  border-color: var(--primary-100);
}

.star {
  color: var(--red);
  margin-left: 0.5rem;
}

.disabled {
  background-color: var(--grey-200);
  border-color: var(--grey-200);
}
