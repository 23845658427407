.inputModalSelect {
  width: 100%;
  display: grid;
  align-items: center;
  margin-bottom: 1.5rem;
  font-size: 0.85rem;

  label {
    color: var(--grey-800);
  }

  select {
    width: 100%;
    height: 33px;
    border-radius: 6px;
    border: 0.5px solid var(--dark-grey);
    padding: 0.4rem 0.5rem;
    color: var(--dark-blue);
    opacity: 1;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    font-family: "ManropeRegular";
    line-height: 1.33;
    background-image: url("../../../../assets/img/icons/arrow-forward.png");
    background-repeat: no-repeat;
    background-position: right 1rem top 50%;
    background-size: 0.75rem;

    &:focus,
    &:active {
      border-width: 2px;
      outline: none;
      border-color: var(--primary-100);
      background-image: url("../../../../assets/img/icons/arrow-bottom.png");
    }

    &::-webkit-input-placeholder {
      color: var(--light-grey);
    }
  }
}

.disabled {
  background-color: var(--grey-200);
  border-color: var(--grey-200) !important;
  background-image: none !important;
}