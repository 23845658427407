.emailSignSection {
  h2 {
    margin-bottom: 4rem !important;
  }
  p {
    text-align: start;
  }
}

.errorSignSection {
  h2 {
    margin-top: 2rem;
    margin-bottom: 6rem !important;
    padding: 0 6rem;
  }
}
