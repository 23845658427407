.expandable {
  background-color: var(--grey-100);
  border-radius: 8px;
  margin-bottom: 4px;
  padding: 0.5rem 1rem;

  label {
    font-family: "ManropeRegular";
    font-size: 0.9rem;
  }
}

.expPickerView {
  margin: 0.5rem 1.5rem 0;
}

.pickerView {
  img {
    width: 20px;
    object-fit: contain;
  }
}

.pickerCell {
  background-color: var(--grey-100);
  border-radius: 8px;
  margin-bottom: 4px;
  padding: 0.26rem 1rem;

  label {
    left: 2.5rem;
  }
}
