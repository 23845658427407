.dashboardCard {
  width: 25.9%;
  min-width: 15rem;
  border-radius: 8px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.15);
  padding: 1rem;
  display: flex;
  align-items: center;
  margin-right: 1.5rem;
  margin-bottom: 1rem;
  overflow: hidden;
  word-break: break-all;

  img{
    width: 50px;
    height: 55px;
    margin-right: 1.5rem;
  }
}
