.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    max-width: 400px;
    margin: 1rem auto;
    padding: 1.5rem;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    transition: background-color 0.3s ease-in-out;
  }
  
  .list {
    width: 100%;
    margin-top: 1rem;
  }
  
  .listItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0;
    border-bottom: 1px solid #f0f0f0;
  
    &:last-child {
      border-bottom: none;
    }
  }
  
  .icon {
    margin-right: 1rem;
  }
  
  .name {
    width: 60%;
    height: 1.5rem;
  }
  
  .number {
    width: 30%;
    height: 1.2rem;
    border-radius: 4px;
  }
  