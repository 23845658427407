.add {
  border-radius: 17.5px;
  height: 2.3rem;
  width: 6rem;
  font-size: 1rem;
}

.container {
  display: grid;
  grid-template-columns: 30% auto;
  column-gap: 1.125rem;

  .messageContainer {
    background-color: var(--neutral-white);
    border-radius: 8px;
    // overflow: auto;
    // overflow-x: hidden;

    .searchInputContainer {
      background-color: var(--white);
      padding: 1rem;
      border-bottom: 0.5px solid var(--grey-300);
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
    }

    .messagesList {
      max-height: 100%;
      overflow: auto;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: var(--grey-500);
        border-radius: 5px;
      }
      &::-webkit-scrollbar-track {
        background-color: var(--grey-50);
        border-radius: 4px;
      }
    }

    .noMessage {
      border: 1px solid var(--grey-500);
      border-radius: 8px;
      background-color: var(--neutral-white);
      margin: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 90%;

      span {
        display: block;
        margin-top: 1rem;
        line-height: 1.5;
        color: var(--grey-700);
        font-size: 0.9rem;
      }
    }
  }
}

.noContentImg {
  border-radius: 8px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.15);
  width: 100%;
}

.btnsContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
