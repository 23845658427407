.card {
  border-radius: 6px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.15);

  .sectionList {
    padding: 1rem;
    display: flex;
    flex-direction: column;
  }

  .subsectionsList {
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
  }

  .sectionItem{
    .expandableMenu {
      padding-left: 2rem;
      height: 3.5rem;
      span {
        font-size: 1.125rem;
        font-weight: 500;
      }
    }
  }
}

.btnConfirm {
  background-color: var(--error) !important;
  color: var(--neutral-white) !important;
}

.loaderContainer {
  height: 20rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  
  .loader {
    width: 30px;
    height: 1.8rem;
    border-width: 6px;
  }
}

.langList {
  padding-left: 1rem;
}
