.card {
  border-radius: 8px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.15);
  padding: 1rem 0.5rem 0.8rem;
  max-width: 22rem;
  min-width: 12rem;
}

.row {
  display: grid;
  grid-template-columns: 45% 55%;
  font-size: 0.95rem;
  margin-bottom: 0.1rem;

  label {
    text-align: right;
  }

  span {
    margin-left: 0.3rem;
  }
}

.marginBottom {
  margin-bottom: 1rem;
}

.bold {
  font-family: "ManropeExtraBold";
}

.actionContainer {
  text-align: center;
  padding: 1rem 0;

  .btn {
    background-image: none;
    background-color: var(--primary-25);
    color: var(--primary-dark);
    width: 7rem;
  }
}
