.modal {
  padding: 1.125rem 1.5rem 1.5rem;
  background-color: var(--background);
  user-select: none;
  max-height: 42.5rem;
  width: 50rem;
  min-height: 20rem;

  h2 {
    margin: 0;
    margin-bottom: 2rem;
  }

  .expContainer {
    padding: 0rem 1rem 2rem;
  }

  .gridRow {
    display: grid;
    grid-template-columns: 25% 75%;
    align-items: center;
  }

  .cast {
    grid-row-start: 3;
    position: relative;
  }

  .castContainer {
    border-radius: 8px;
    border: solid 1px var(--grey-500);
    padding: 1.5rem 1rem;
    max-height: 36rem;
    overflow-y: auto;
  }

  .alignStart {
    align-items: flex-start;
  }

  .actionContainer {
    text-align: center;
    padding-top: 1.5rem;
  }

  .seasonContainer {
    padding-top: 0.75rem;
    padding-left: 1.5rem;
    margin-bottom: 1rem;

    .seasonList {
      padding: 0.875rem 0;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      row-gap: 0.5rem;
      max-height: 6rem;
      overflow-y: auto;
      border-bottom: 1px solid var(--primary-100);

      .seasonItem {
        width: 5.7rem;
        background-image: none;
        background-color: var(--neutral-white);
        border: 1px solid var(--primary-400);
        border-radius: 17.5px;
        color: var(--primary-400);
        line-height: 1.22;
        font-size: 0.9rem;

        &:hover {
          background-image: linear-gradient(
            107deg,
            var(--secondary-400),
            var(--primary-400) 89%
          );
          color: var(--white);
          border-color: var(--white);
        }
      }

      .selected {
        background-image: linear-gradient(
          107deg,
          var(--secondary-400),
          var(--primary-400) 89%
        );
        color: var(--white);
        border-color: var(--white);
      }
    }
  }

  .headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-right: 1rem;

    span {
      font-family: "ManropeSemiBold";
    }

    .actions {
      display: flex;
      align-items: center;

      .btn {
        margin-left: 1rem;

        img {
          width: 30px;
          height: 30px;
          object-fit: contain;
        }
      }
    }

    .disabled {
      color: var(--grey-500);
    }
  }

  .disabledLine {
    border-image-source: none;
    border-color: var(--background);
  }

  .marginLeft {
    margin-left: 1.5rem;
  }

  .episodeContainer {
    padding-bottom: 1rem;

    .line {
      margin: 1rem 0;
    }

    .episodeList {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 0.95rem;
      row-gap: 1.5rem;
      padding: 0 1rem;

      .episodeItem {
        border-radius: 5px;
        box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.15);
        background-color: var(--neutral-white);
        overflow: hidden;
        min-height: 8.4rem;
        // cursor: pointer;
        position: relative;

        &:hover {
          .btnDelete {
            opacity: 1;
            transition: opacity 0.5s;
          }
        }

        img {
          height: 6.25rem;
          width: 100%;
          object-fit: cover;
        }

        .episodeNameContainer {
          display: block;
          padding: 0 0.5rem 0.5rem;

          span {
            font-size: 0.875rem;
          }

          .episodeName {
            margin-left: 0.2rem;
          }
        }
      }

      .btnDelete {
        opacity: 0;
        transition: opacity 0.5s;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #ffffffd6;
        border-radius: 50%;
        width: 30px;
        height: 30px;

        img {
          width: 30px;
          height: 30px;
          object-fit: cover;
        }
      }
    }
  }

  .closeBtn {
    background-image: none;
    border: 1px solid var(--grey-900);
    background-color: transparent;
    color: var(--grey-1000);
  }
}
