.catItem {
  padding-left: 2rem;
}

.btnRankContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;

  .btn {
    margin-left: 1.5rem;
    img {
      width: 40px;
      height: 40px;
    }
  }
}
