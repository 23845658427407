.loaderContainer {
  height: 20rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .loader {
    width: 30px;
    height: 1.8rem;
    border-width: 6px;
  }
}

.card {
  border-radius: 6px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.15);
  padding-bottom: 1rem;
  min-height: 26rem;

  .cardTitle {
    font-size: 0.9rem;
    color: var(--dark-grey);
    padding: 1.5rem 0 1.5rem 1.3rem;
    margin: 0;
  }

  .itemsContainer {
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;
    margin-right:1.5rem;
    margin-left:1.5rem;

    .draggableItemContainer{
      margin-bottom:0.5rem
    }
  }
}

.confModal{
  p{
    color: var(--grey-1000) !important;
    margin-bottom: 0;
  }
}



