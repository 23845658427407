.modal {
  padding: 1rem;
  text-align: center;
  z-index: 400;
  width: 21.875rem;

  span {
    display: block;
    line-height: 1.33;
    width: 18rem;
  }

  p {
    color: var(--grey-800);
    margin-top: 0.5rem;
    font-size: 0.9rem;
  }

  .name {
    display: block;
  }
}

.backdrop {
  z-index: 300;
}

.actionsContainer {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.8rem;

  .btnCancel {
    background-image: none;
    background-color: transparent;
    border: 1px solid var(--grey-650);
    color: var(--grey-1000);
    margin-right: 0.5rem;
  }

  .btnConfirm {
    background-image: none;
    background-color: var(--error);
    color: var(--white);
    margin-left: 0.5rem;
  }

  .loader {
    width: 30px;
    height: 30px;
    border-width: 6px;
  }
}
