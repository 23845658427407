.sortingContainer {
  margin-top: 2rem;
}

h2 {
  margin: 0;
  color: var(--primary-700);
  font-family: "Neutra";
}

.tableStyle {
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}

.table {
  width: 100%;
  border-collapse: collapse;

  thead {
    background-color: var(--primary-25);
    font-family: "ManropeSemiBold";
  }

  th,
  td {
    padding: 1rem;
  }

  .thContainer {
    display: flex;
    align-items: center;
    // justify-content: center;
    cursor: pointer;
    user-select: none;

    img {
      margin-left: 0.5rem;
    }
  }
}

.arrowsContainer {
  display: flex;
  flex-direction: column;
  margin-left: 0.7rem;

  .arrow {
    width: 0;
    height: 0;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    cursor: pointer;
  }

  .up {
    border-bottom: 4px solid var(--dark-blue);
    margin-bottom: 0.3rem;
  }

  .down {
    border-top: 4px solid var(--dark-blue);
  }
}

