.list {
  .historySpan {
    display: grid;
    grid-template-columns: 5% 90%;
    align-items: center;
    font-size: 0.95rem;
    line-height: 1.25;

    .circle {
      width: 0.6rem;
      height: 0.6rem;
    }
  }

  .dash {
    border-left: 1px dashed var(--primary-400);
    height: 1.5rem;
    margin-left: 0.25rem;
  }
}
