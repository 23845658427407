.page {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 90% 10%;
}

.card {
  width: 80%;
  max-width: 65rem;
  height: 80%;
  max-height: 45rem;
  min-height: 30rem;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(2, 50%);
  overflow: hidden;
}

.fixed {
  background-image: linear-gradient(
    139deg,
    var(--secondary-400),
    var(--primary-400) 87%
  );
  text-align: center;

  p {
    margin-top: 4rem;
    padding: 0 4rem;
    color: var(--white);
    font-size: 1.3rem;
    font-family: "ManropeMedium";
  }

  img {
    margin-top: 5rem;
    width: 80%;
    max-width: 25rem;
    object-fit: contain;
  }
}

.sign {
  background-color: var(--white);
  padding: 3rem 1.5rem 3rem 2.5rem;
  position: relative;

  h2 {
    margin: 0;
    color: var(--primary-400);
    line-height: 1.27;
    text-align: center;
    font-family: "NeutraBold";
  }

  p {
    text-align: center;
    line-height: 1.26;
    font-size: 0.9rem;
    margin-bottom: 1.5rem;
  }

  .input {
    label {
      display: block;
      margin-bottom: 0.7rem;
    }
  }

  .customInput {
    height: 32px;
  }

  .marginBottom {
    margin-bottom: 4rem;
  }

  .warning {
    margin-top: 1rem;
    color: var(--warning);
    font-size: 0.7rem;
    text-align: start;
    display: flex;
    align-items: center;

    img {
      width: 15px;
      height: 15px;
    }

    span {
      margin-left: 0.5rem;
    }
  }

  .forgotPassword {
    display: block;
    color: var(--primary-400);
    line-height: 1rem;
    font-size: 0.8rem;
    margin-top: 1.5rem;

    a {
      color: var(--primary-400);
    }
  }

  .actionContainer {
    position: absolute;
    bottom: 3rem;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;

    .loader {
      width: 30px;
      height: 30px;
      border-width: 6px;
    }
  }
}
