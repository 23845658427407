.navbar {
  background-color: var(--dark-blue);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
  font-family: "ManropeMedium";

  .logoContainer {
    margin-top: 2rem;
    outline: none;
  }

  .logo {
    width: 100%;
    object-fit: contain;
  }

  ul {
    width: 100%;
  }

  .profileSection {
    height: 5rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    box-shadow: 0 -4px 10px 2px rgba(0, 0, 0, 0.5);
    cursor: pointer;
  }

  .userOval {
    position: relative;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    background-color: var(--dark-blue); /* Inner circle color */
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      content: "";
      position: absolute;
      top: -1px; /* Adjust for border width */
      left: -1px; /* Adjust for border width */
      right: -1px; /* Adjust for border width */
      bottom: -1px; /* Adjust for border width */
      border-radius: 50%;
      background: linear-gradient(
        134deg,
        var(--secondary-400),
        var(--primary-400) 87%
      );
      z-index: -1;
    }
  }
}

.drawer {
  width: 8rem;
  height: 100%;
  position: absolute;
  top: 0;
  // padding-top: 8rem;
  padding-top: 14rem;
  box-sizing: border-box;
  background-color: var(--dark-blue-opacity);
  box-shadow: 2px 0 4px -4px rgba(0, 0, 0, 0.5);
  display: grid;
  grid-template-rows: repeat(8, 1fr);
  left: 0;
  transition: left 0.7s;
  white-space: normal;
  overflow-wrap: anywhere;
  overflow-y: auto;
  z-index: 5;

  a {
    padding: 0;
    display: block;
    margin-bottom: 1.5rem;
    // text-align: right;
    // padding-right: 1rem;
    padding-left: 1.5rem;
    font-size: 0.85rem;
    text-decoration: none;
    color: var(--primary-400);
    outline: none;

    &:hover,
    &:active {
      color: var(--primary-100);
      border-left: 5px solid var(--primary-100);
    }

    @media (max-width: 1200px) {
      padding-right: 1rem;
    }
  }

  @media (max-width: 1200px) {
    width: 7rem;
  }
}

.active {
  left: 9rem;
  visibility: visible;

  @media (max-width: 1110px) {
    left: 7rem;
  }
}

.inactive {
  left: 0;
}

.confModal {
  width: 25rem !important;
}
