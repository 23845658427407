.channelCard {
  &:hover {
    .foreground {
      height: 0;
      .container {
        opacity: 0;
        transition: opacity 1s;
      }
    }

    .linked,
    .container {
      visibility: hidden;
    }
  }

  .foreground {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--dark);
    transition: height 0.5s;
    color: var(--neutral-white);

    .container {
      padding: 1.2rem 0.4rem;
      display: flex;
      align-items: center;
      flex-direction: column;
      font-size: 0.875rem;
      font-family: "ManropeExtraBold";
      text-align: center;
      opacity: 1;
      transition: all 1s;

      .imgContainer {
        margin: 0.5rem 0 1.2rem;
        height: 4.3rem;
        width: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

      }
    }

    .linked {
      position: absolute;
      right: 0.5rem;
      top: 0.5rem;
    }
  }
}
