.cell {
  font-size: 0.9rem;

  div {
    position: relative;
    display: flex;
    align-items: center;
  }

  img {
    position: absolute;
    left: 0;
    width: 17px;
    object-fit: contain;
  }

  label {
    position: absolute;
    left: 1rem;
    cursor: pointer;
  }

  .icon {
    left: 1.7rem;
    width: 17px;
  }
}

.bold {
  font-family: "ManropeSemiBold";
}

.bgColor {
  background-color: var(--primary-25) !important;
}

.defaultCursor {
  label {
    cursor: default;
  }
}
