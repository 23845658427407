.modal {
  width: 50rem;
  min-height: 25rem;
  max-height: 43.5rem;

  .gridLayout {
    display: grid;
    grid-template-columns: 20% 75%;
    column-gap: 1.875rem;
    padding: 0 1.875rem;

    label {
      color: var(--grey-800);
      font-size: 0.85rem;
    }

    .btnLinkContainer {
      grid-column-start: 2;
      display: grid;
      grid-template-columns: 25% 75%;

      .btnLink {
        grid-column-start: 2;
        width: 100%;
        margin-bottom: 1rem;
        height: 2rem;
        background-image: none;
        background-color: var(--primary-25);
        color: var(--primary-dark);
        line-height: 1.22;
        font-size: 1rem;

        &:hover {
          background-image: linear-gradient(
            106deg,
            var(--secondary-400),
            var(--primary-400) 89%
          );
          color: var(--white);
        }
      }

      .langListContainer {
        grid-column-start: 2;
        margin-bottom: 1.5rem;
      }
    }

    .fileContainer {
      position: relative;
      width: 8.5rem;

      div {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      span {
        display: block;
        font-size: 0.75rem;
        color: var(--grey-800);
        margin-top: 0.7rem;
      }
    }

    .fileInput {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      width: 100%;
      height: 45%;
      cursor: pointer;
    }

    .image {
      width: 100%;
      height: 12rem;
      object-fit: contain;
      // cursor: pointer;
      border-radius: 8px;
      box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.15);
    }

    .customInput {
      grid-template-columns: 25% 75%;
    }

    .linkedContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;

      img {
        cursor: pointer;
        width: 20px;
      }

      .linked {
        font-size: 0.9rem;
      }
    }

    .metadata_span {
      grid-column-start: 2;
      color: var(--grey-800);
      font-size: 0.7rem;
      grid-column-start: 2;
      margin-top: 0.3rem;
    }

    .pickerViewContainer {
      .pickerView {
        background-color: var(--grey-50);
        border-radius: 13.5px;
        padding: 0.6rem 1.5rem;
        margin-bottom: 0.6rem;
        max-height: 12rem;
      }

      span {
        color: var(--grey-800);
        line-height: 1.43;
        font-size: 0.85rem;
        margin-left: 1.5rem;
      }

      .pickerCell {
        label {
          left: 2.25rem;
          color: var(--dark-blue);
          font-size: 0.85rem;
          line-height: 1.25;
        }

        img {
          width: 20px;
        }
      }
    }

    .gridRow {
      display: grid;
      grid-template-columns: 25% 75%;
      align-items: center;
      margin: 1.5rem 0;
    }

    .alignStart {
      align-items: flex-start;
    }
  }

  .actionContainer {
    text-align: center;
    padding: 1.5rem 0;
  }
}

.taken {
  border-color: var(--error);
}

.noMarginTop {
  margin-top: 0 !important;
}

.noMargin {
  margin: 0 !important;
}

.marginTop {
  margin-top: 0.5rem;
}

.columnStart {
  grid-column-start: 2;
}

.btn {
  width: 100%;
}

.marginLeft {
  margin-left: 0.5rem;
}

.linkActionsContainer {
  grid-column-start: 2;
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  width: 30px;
  height: 1.8rem;
  border-width: 6px;
}
