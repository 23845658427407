.messageItem {
  position: relative;
  padding: 1rem;
  background-color: var(--white);
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 0.5px solid var(--disabled);
  }

  // &:last-child {
  //   border-bottom-left-radius: 8px;
  //   border-bottom-right-radius: 8px;
  // }

  &::after {
    position: absolute;
    top: 50%;
    left: 100%;
    margin-top: -1rem;
    border-width: 1rem;
    border-style: solid;
    border-color: transparent transparent transparent var(--primary-700);
  }
}

.selected {
  background-color: var(--primary-700);
}

.showArrow {
  z-index: 2;
  &::after {
    content: "";
  }
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .messageTitle {
    font-family: "ManropeExtraBold";
    line-height: 1.25;
    font-size: 0.9rem;
    word-break: break-all;
  }

  .messageTime {
    color: var(--grey-800);
    font-size: 0.75rem;
  }

  p {
    font-size: 0.75rem;
    line-height: 1.5;
    width: 83%;
    margin: 0;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .icons {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    img {
      width: 20px;
      height: 20px;
    }

    .pinned {
      &:hover {
        opacity: 0.7;
      }
    }
  }
}

.white {
  color: var(--white) !important;
}

.rowHeight {
  height: 4rem;
  margin-top: 0.6rem;
}

.loader {
  width: 20px;
  height: 20px;
  border-width: 4px;
}
