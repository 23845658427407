.backdrop {
  z-index: 250;
}

.modal {
  border-radius: 24px;
  box-shadow: 0 2px 7px 2px rgba(33, 37, 71, 0.4);
  width: 80%;
  z-index: 300;
  min-height: 30rem;

  .moveActionsContainer {
    height: 8rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    button {
      background-color: var(--neutral-white);
    }
  }

  .sectionsContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: 33rem;
  }

  .actionContainer {
    text-align: center;
    padding: 1.5rem 0;
  }
}
