* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

#root {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  background-color: var(--background);
  color: var(--dark-blue);
  font-family: "ManropeRegular";
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.public-DraftStyleDefault-ul {
  list-style-type: disc;
  padding-left: 30px;
}

@font-face {
  font-family: "Neutra";
  src: url("./assets/fonts/Neutra-Text-TF-Demi-Alt.otf");
}

@font-face {
  font-family: "NeutraBold";
  src: url("./assets/fonts/Neutra-Text-TF-Bold-Alt.otf");
}

@font-face {
  font-family: "ManropeBold";
  src: url("./assets/fonts/Manrope-Bold.ttf");
}

@font-face {
  font-family: "ManropeExtraBold";
  src: url("./assets/fonts/Manrope-ExtraBold.ttf");
}

@font-face {
  font-family: "ManropeExtraLight";
  src: url("./assets/fonts/Manrope-ExtraLight.ttf");
}

@font-face {
  font-family: "ManropeLight";
  src: url("./assets/fonts/Manrope-Light.ttf");
}

@font-face {
  font-family: "ManropeMedium";
  src: url("./assets/fonts/Manrope-Medium.ttf");
}

@font-face {
  font-family: "ManropeRegular";
  src: url("./assets/fonts/Manrope-Regular.ttf");
}

@font-face {
  font-family: "ManropeSemiBold";
  src: url("./assets/fonts/Manrope-SemiBold.ttf");
}
