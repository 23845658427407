.modal {
  width: 50rem;
  max-height: 50rem;
  background-color: var(--background);

  .langContainer {
    padding: 0rem 1.5rem 1rem 1.5rem;
  }

  .wrapper {
    padding: 1.5rem;
    padding-top: 0rem;
    display: flex;
    flex-direction: column;
    max-height: 25rem; // increase its valueif needed
    overflow-y: scroll;
    scrollbar-width: thin;
  }

  .sectionContainer {
    padding-bottom: 1rem;
    div{
      margin-top: 0;
    }
  }

  .inputWrapper {
    padding: 1.5rem;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1.125rem;
  }

  .customInput {
    height: 40px !important;

    div {
      border-radius: 8px;
      border: 1px solid var(--grey-800);
    }

    input {
      height: 40px !important;
      background-color: transparent !important;
      font-size: 16px;
      color: var(--primary-900);
    }
  }

  .subsectionsContainer {
    display: flex;
    flex-direction: column;
    transform: none;
 
    width: 48rem
  }

  .draggable {
    width: 47rem;
    margin-bottom: 1rem;
    div{
      margin-top: 0rem !important;
    }
  }

  .expandableMenu {
    padding-left: 2rem;
    height: 3.5rem;
    span {
      font-size: 1.125rem;
      font-weight: 600;
    }
  }
  .btnContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
    padding-top: 1rem;

    .addSubsectionBtn {
      border-radius: 18px;
      border: 1px solid var(--primary-400);
      width: 243px;
      height: 35px;
      background-color: transparent;

      img {
        width: 20px;
        height: 20px;
      }

      span {
        font-family: "ManropeRegular";
        font-size: 1.125rem;
        color: var(--primary-400);
      }
    }
  }

  .btnActions {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 1.5rem 0;

    button {
      height: 35px;
      width: 100px;
      font-size: 1.125rem;
    }

    .btnCancel {
      border: solid 1px var(--grey-900);
      background-image: none;
      background-color: transparent;
      color: var(--grey-900);
    }
  }
}
