.card {
  border-radius: 8px;
  box-shadow: 0 2px 7px 2px rgba(33, 37, 71, 0.15);

  p {
    font-size: 0.9rem;
    color: var(--dark-grey);
    padding: 1.5rem 0 1.5rem 1.3rem;
    margin: 0;
  }

  .infoContainer {
    padding: 0 1.3rem 1.3rem;
  }

  .langList {
    padding: 1rem 0.1rem 0.5rem;
    margin-bottom: 1.5rem;
  }

  .actionContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem 0;
  }

  .loader {
    width: 30px;
    height: 1.8rem;
    border-width: 6px;
  }
}
