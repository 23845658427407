.modal {
  width: 50rem;
  user-select: none;
  max-height: 43.5rem;
  min-height: 15rem;
  background-color: var(--background);

  .durationRow {
    grid-row-start: 1;
    display: grid;
    grid-template-columns: 25% 75%;
    align-items: center;
    margin-top: 1.5rem;

    .timeContainer {
      display: flex;

      .timeUnit {
        text-align: center;
        width: 6rem;

        input {
          text-align: center;
        }
      }

      .colon {
        align-self: flex-end;
        margin: 0 auto;

        .dot {
          width: 0.25rem;
          height: 0.25rem;
          margin-bottom: 0.4rem;
        }
      }
    }
  }

  .gridRow {
    display: grid;
    grid-template-columns: 25% 75%;
    align-items: center;
  }

  .alignStart {
    align-items: flex-start;
  }

  .price {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    text-align: center;
  }

  .customInput {
    height: 33px !important;
    input {
      padding-left: 1.5rem;
      padding-right: 0.5rem;
    }
  }

  .select {
    margin-bottom: 0;
  }

  select {
    padding-left: 1.5rem;
  }

  .line {
    margin-top: 1rem;
  }

  .actionContainer {
    text-align: center;
    padding: 1.5rem 0;
  }

  .closeBtn {
    background-image: none;
    border: 1px solid var(--grey-900);
    background-color: transparent;
    color: var(--grey-1000);
  }

  .inputHeight {
    height: fit-content !important;
  }

  .urlMovie {
    grid-row-start: 6;
  }
}
