.customerRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0.2rem 0.5rem 0;
  font-size: 0.9rem;
  border-bottom: 1px solid #c9a8ff61;
  user-select: none;

  .arrow {
    width: 12px;
    height: 12px;
    object-fit: contain;
    margin-left: 0.7rem;
  }

  .actionContainer {
    display: flex;
    align-items: center;

    button {
      width: fit-content;

      img {
        width: 24px;
        height: 24px;
      }
    }

    span {
      font-size: 0.75rem;
      letter-spacing: 0.74px;
    }
  }
}

.subscribers {
  font-size: 0.9rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 1.5rem;
  padding: 0.5rem 1.7rem 0.5rem 0;
  border-bottom: 1px solid #c9a8ff61;
}

.span {
  font-size: 0.8rem;
  letter-spacing: 0.74px;
}

.ml {
  margin-left: 0.5rem;
}

.green {
  color: var(--message-green);
}

.red {
  color: var(--message-red);
}
