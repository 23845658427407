.page {
  height: 100%;
}

.list {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 1.5rem;
  row-gap: 1.25rem;

  @media (min-width: 1750px) {
    grid-template-columns: repeat(7, 1fr);
  }

  @media (max-width: 1536px) {
    grid-template-columns: repeat(6, 1fr);
  }

  @media (max-width: 1440px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media (max-width: 1200px) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .sortBtn {
    margin-right: 1rem;
  }

  .btn {
    img {
      width: 40px;
      height: 40px;
    }
  }
}

.menuItemsList {
  max-height: 21rem !important;
}

.channelCard {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  transition: 0.3s all;
  &:hover{
    background-size: 100% 0;
  }
}

.paginationFooter {
  width: 100%;
  margin-top: 20px;
}