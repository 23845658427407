.pickerView {
  overflow-y: auto;
  user-select: none;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--grey-500);
    border-radius: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: var(--grey-50);
    border-radius: 4px;
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1rem;
}
