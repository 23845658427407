.invalid {
  border-color: var(--warning);
}

.icon {
  img {
    width: 20px;
    height: 20px;
  }
}
