.footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "ManropeMedium";

  span {
    color: var(--dark-blue);
    font-size: 0.75rem;
  }
}
