.expContainer {
  padding: 0 2.5rem;
}

.expMenuClassName {
  margin-top: 1.5rem;
}

.container {
  padding: 0 4rem;

  .langListContainer {
    grid-column-start: 2;
    margin-bottom: 1.5rem;
    padding-top: 0.5rem;
  }

  label {
    color: var(--grey-800);
    font-size: 0.85rem;
  }

  .titleInput {
    grid-template-columns: 25% 75% ;
    input {
      padding-left: 1.5rem;
    }
  }

  .textAreaRow {
    display: grid !important;
    grid-template-columns: 25% 75%;

    textarea {
      outline: none;
      color: var(--dark-blue);
      font-family: "ManropeRegular";
      border-radius: 8px;
      border: solid 0.5px var(--grey-800);
      padding: 0.5rem 1.5rem;
      width: 100%;
      margin-bottom: 0.4rem;
      height: 8.8rem;
      resize: none;

      &:focus {
        border: solid 2px var(--primary-100);
      }

      &::-webkit-input-placeholder {
        color: var(--light-grey);
      }
    }

    .disabled {
      background-color: var(--grey-200);
      border-color: var(--grey-200);
    }

    .charsContainer {
      justify-content: flex-end;
      font-size: 0.875rem;

      .abc {
        color: var(--primary-100);
      }

      .chars {
        line-height: 1.43;
        color: var(--grey-800);
        margin-left: 1.5rem;
      }
    }
  }

  .row {
    display: flex;
    align-items: center;
  }

  .uploadResolution {
    font-size: 0.75rem;
    color: var(--grey-800);
    margin-top: 0.5rem;
  }

  .contentContainer {
    grid-column-start: 2;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .gridRow {
    display: grid;
    grid-template-columns: 25% 75%;
    align-items: center;
  }

  .noMargin {
    margin: 0;
  }

  .alignStart {
    align-items: flex-start;
  }

  .cast {
    grid-row-start: 3;
    position: relative;
  }

  .castContainer {
    border-radius: 8px;
    border: solid 1px var(--grey-500);
    padding: 1.5rem 1rem;
    max-height: 36rem;
    overflow-y: auto;
  }

  .pickerViewContainer {
    .pickerView {
      background-color: var(--grey-50);
      border-radius: 13.5px;
      padding: 0.6rem 1.5rem;
      margin-bottom: 0.6rem;
      max-height: 12rem;
    }

    span {
      color: var(--grey-800);
      line-height: 1.43;
      font-size: 0.85rem;
      margin-left: 1.5rem;
    }

    .pickerCell {
      label {
        left: 2.25rem;
        color: var(--dark-blue);
        font-size: 0.9rem;
        line-height: 1.25;
      }
    }
  }

  .selectLang {
    margin: 0;
    // margin-right: 1rem;
  }

  .select {
    margin-bottom: 0;
  }

  select {
    padding-left: 1.5rem;
  }

  .input {
    width: 100%;

    input {
      padding-left: 1.5rem;
    }

    &:last-child {
      height: fit-content !important;
    }
  }

  .customInput {
    height: 33px !important;
    input {
      padding-left: 1.5rem;
      padding-right: 0.5rem;
    }
  }

  .btn {
    grid-column-start: 2;
    width: 100%;
    margin-top: 0.5rem;
  }
}

.imagesContainer {
  padding: 0 1rem 1.5rem;

  .imageRowContainer {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 1.8rem;
    margin-top: 3rem;

    span {
      display: block;
      margin-bottom: 0.5rem;
      font-size: 0.95rem;
    }
  }

  .fileContainer {
    position: relative;

    &:hover {
      .btnEdit {
        opacity: 1;
      }
    }

    div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .fileInput {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    &::-webkit-file-upload-button {
      display: none;
    }
  }

  .btnEdit {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 30px;
    height: 30px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;

    img {
      width: 15px;
      height: 15px;
    }
  }

  .btnPoster {
    bottom: 1.3rem;
    top: unset;
    left: 50%;
    transform: translateX(-50%);
  }

  .poster {
    width: 8.5rem;
    height: 12.5rem;
    object-fit: cover;
    border-radius: 8px;
  }

  .preview {
    width: 32.8rem;
    height: 12.5rem;
    object-fit: cover;
    border-radius: 8px;
  }

  .landscape {
    width: 20rem;
    height: 11.25rem;
    object-fit: cover;
    border-radius: 8px;
  }

  .background {
    width: 21.25rem;
    height: 12.5rem;
    object-fit: cover;
    border-radius: 8px;
  }
}
