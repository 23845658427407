.expSelectInput {
  cursor: pointer;

  .customInput {
    margin-bottom: 0;

    label {
      color: var(--grey-1000);
    }

    input {
      cursor: pointer;
    }
  }

  .grid {
    display: grid;
    grid-template-columns: 30% 70%;

    .card {
      grid-column-start: 2;
      border-radius: 8px;
      box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.2);
      margin-top: 0.3rem;
    }
  }
}
