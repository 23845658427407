.container {
  display: flex;
  align-items: center;

  .searchInput {
    border-color: var(--grey-250);
  }

  .expBtn {
    // min-width: 7rem;
    max-width: 10.5rem;
    background-color: var(--neutral-white);
    border: solid 1px var(--grey-250);
    border-radius: 8px;
    height: 2.5rem;
    font-family: "ManropeRegular";
    justify-content: space-between;
    padding: 0 0.7rem;
    margin-left: 1rem;

    span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .expCard {
    width: 14rem;
    top: 2.6rem;
    left: 0;
    box-shadow: none;
    border: solid 1px var(--grey-500);
  }

  .pickerView {
    padding: 0.7rem;

    label {
      left: 2.5rem;
    }

    img {
      width: 18px;
    }
  }

  .searchInput {
    width: 20rem;
  }
}
