.page {
  display: flex;
  align-items: center;
  // justify-content: center;
  flex-direction: column;
  height: 100%;
  background-color: var(--background);

  h1 {
    font-size: 10rem;
    margin-bottom: 1rem;
  }

  h2 {
    font-size: 2rem;
  }

  .btn{
    margin-top: 1rem;
    width: 6rem;
    height: 2rem;
  }
}
