.cast {
  border-radius: 8px;
  border: solid 1px var(--grey-500);
  height: 2.3rem;
  display: grid;
  grid-template-columns: 6.5rem 17.5rem;
  overflow: hidden;

  .selectRole {
    background-color: var(--grey-100);
    height: 100%;
    justify-content: space-between;
    padding: 0 1rem;
    position: static;
    font-family: "ManropeRegular";

    img {
      width: 12px;
      height: 12px;
    }
  }

  input {
    height: 100%;
    border: none;
    outline: none;
    padding: 0 1rem;

    &::-webkit-input-placeholder {
      color: var(--grey-500);
    }
  }
}

.rolesMenu {
  border: solid 1px var(--grey-500);
  padding: 0.5rem;
  width: 6.5rem;
  box-shadow: none;
  top: 4rem;
  left: 10rem;

  span {
    display: block;
    text-align: center;
    padding: 0.1rem 0;
    cursor: pointer;

    &:hover {
      background-color: var(--primary-25);
      border-radius: 4px;
    }
  }
}

.rolesMenuBackdrop {
  height: 210%;
}

.membersMenu {
  width: 18rem;
  max-height: 14.1rem;
  overflow-y: auto;
  position: absolute;
  left: 16.5rem;
  background-color: var(--neutral-white);
  border-radius: 8px;
  border: solid 1px var(--grey-500);
  padding: 0.5rem;
  z-index: 3;

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 0.2rem 0.5rem;
    padding-right: 0;

    span {
      font-size: 0.8rem;
      line-height: 1.29;
      margin-right: 0.5rem;
    }

    .role {
      line-height: 1.43;
      color: var(--grey-700);
      font-size: 0.6rem;
    }

    &:hover {
      background-color: var(--primary-25);
      border-radius: 4px;
    }
  }
}

.noResult {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0;

  img {
    width: 81px;
    height: 81px;
  }

  span {
    margin: 1rem 0;
  }

  button {
    width: fit-content;
  }
}

.noMarginTop {
  margin-top: 0 !important;
}
