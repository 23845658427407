.item {
  display: flex;
  align-items: center;

  .color {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    border-radius: 4px;
  }

  span {
    font-size: 0.75rem;
    line-height: 1.5;
  }
}
