.expandable {
  padding-bottom: 0.8rem;
}

.expPicker {
  margin: 1rem 1.5rem;
  &:not(:last-child) {
    border-bottom: 1px solid var(--grey-300);
    padding-bottom: 1rem;
  }
}

.pickerView {
  background-color: var(--neutral-white);
  padding: 0.7rem 1.5rem 0.6rem;
  cursor: pointer;

  label {
    font-family: "ManropeSemiBold";
    font-size: 1rem;
    left: 2.5rem;
  }
}

// .border {
//   border-bottom: 1px solid var(--primary-100);
// }

.profile {
  margin-top: 1rem;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.15);
  border-radius: 6px;
}

.container {
  padding: 1.5rem 2rem;
}

.row {
  display: flex;
  align-items: center;
  cursor: pointer;

  label {
    margin-left: 1rem;
    cursor: pointer;
  }

  img {
    width: 17px;
    height: 17px;
  }
}

.marginBottom {
  margin-bottom: 1rem;
}
