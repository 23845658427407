.item {
  padding: 0.65rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 0.25rem;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: var(--neutral-white);

  &:hover {
    color: var(--primary-400);
  }

  .container {
    display: flex;
    align-items: center;
    max-width: 80%;
  }

  .name {
    font-size: 0.875rem;
  }

  .icon {
    object-fit: contain;
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.6rem;
    background-color: var(--dark-blue);
  }

  .number {
    color: var(--grey-800);
    font-size: 0.75rem;
    line-height: 1.33;
    font-family: "ManropeRegular";
    white-space: nowrap;
  }
}

.selected {
  color: var(--primary-400);
  font-family: "ManropeExtraBold";
}

.dragging {
  background-color: var(--grey-200);
}

.draggingHover {
  background-color: var(--primary-50);
  font-family: "ManropeExtraBold";
}
