.container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .draggableItem {
    border: 1px solid var(--primary-100);
    border-radius: 20px;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-right: 1rem;
    width: 100%;
    overflow: hidden;

    &.dragging {
      background-image: linear-gradient(
        93deg,
        var(--secondary-400),
        var(--primary-400) 89%
      );
      height: 42px;
      .contentText {
        p {
          color: var(--neutral-white);
        }
      }
    }

    .dragBtnWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;

      .dragIcon {
        width: 20px;
        height: 20px;
      }
    }

    .contentText {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);

      p {
        font-size: 1rem;
        color: var(--primary-900);
        padding: 0px;
      }
      span {
        font-size: 0.9rem;
      }

      .customInput {
        height: fit-content !important;
      }
      .bold {
        .input {
          border: none;
          input {
            font-weight: bold;
          }
        }
      }

      .input {
        border: none;

        input {
          text-align: center;
          background-color: var(--neutral-white);
          font-size: 0.9rem;

          cursor: grab;
        }
      }

      .draggingInput {
        background: transparent;

        input {
          color: var(--neutral-white);
          background: transparent;
        }
      }
    }
  }

  .actionBtnWrapper {
    display: flex;
    align-items: center;
    gap: 16px;
    flex-shrink: 0;
    // flex-basis: content;

    .actionBtn {
      width: 2rem;
      height: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 40px;
        height: 40px;
      }
    }
  }
}

// .draggableItem.dragging {
//   background-image: linear-gradient(93deg,var(--secondary-400),var(--primary-400) 89%);
//   height: 42px;
//   .contentText {
//     p {
//       color: var(--neutral-white);
//     }
//   }
// }
