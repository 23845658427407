.header {
  display: flex;
  padding: 1rem;
  background-color: var(--grey-10);

  .searchInput {
    width: 18.75rem;
    height: 2rem;
    margin-right: 0.5rem;

    input {
      padding: 0;
    }
  }

  .verticalList {
    display: flex;
    overflow-x: auto;
    
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--grey-250);
      border-radius: 4px;
    }
    &::-webkit-scrollbar-track {
      background-color: var(--grey-10);
      border-radius: 4px;
    }

    .verticalItem {
      border-radius: 4px;
      margin-left: 0.5rem;
      font-size: 0.85rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 1rem;
      white-space: nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;
    }
  }
}

.legendContainer {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin: 0.5rem; /* Adjust as needed */
}
