.card {
  border-radius: 8px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.15);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  max-width: 15rem;

  .logo {
    width: 156px;
    height: 49px;
    object-fit: contain;
    margin-bottom: 0.5rem;
  }

  .icon {
    margin-right: 0.5rem;
    width: 20px;
    height: 20px;
    object-fit: contain;
  }

  .name {
    font-family: "ManropeExtraBold";
    // text-align: center;
    max-width: 12rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .infoContainer {
    width: 100%;

    .row {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;

      .content {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      img {
        margin-right: 0.6rem;
      }

      span {
        font-size: 0.95rem;
      }
    }
  }

  .btnViewMore {
    background-image: none;
    background-color: var(--primary-25);
    color: var(--dark-blue);
    width: 6rem;
    margin-top: 1rem;
  }
}

.allOptionsCard {
  position: absolute;
  left: -16rem;
  bottom: 0rem;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 2px 7px 2px rgba(33, 37, 71, 0.4);
  background-color: var(--white);
  width: 15rem;
  z-index: 200;

  &::after {
    content: " ";
    position: absolute;
    top: 50%;
    margin-top: -10px;
    border-width: 10px;
    border-style: solid;
  }

  span {
    line-height: 1.26;
    color: var(--primary-dark);
    font-family: "ManropeSemiBold";
  }

  .allOptionsList {
    margin-top: 0.5rem;
    li {
      color: var(--grey-800);
      display: flex;
      align-items: center;
    }

    .listBullet {
      width: 4px;
      height: 4px;
      margin-right: 5px;
    }
  }
}

.leftCard {
  &::after {
    left: 100%; /* To the right of the tooltip */
    border-color: transparent transparent transparent var(--white);
  }
}

.rightCard {
  &::after {
    right: 100%;
    border-color: transparent var(--white) transparent transparent;
  }
}
