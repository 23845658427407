.inputsContainer {
  width: 80%;

  .input {
    label {
      color: var(--dark-grey);
    }
  }
}

.error{
  color: var(--error) !important;
}
