.textEditor {
  border-radius: 8px;
  border: 1px solid var(--grey-800);
  overflow: hidden;

  .toolbar {
    padding: 1rem;
    display: flex;
    align-items: center;

    .verticalLine {
      width: 1px;
      height: 30px;
      margin: 0 1.5rem 0 0.5rem;
      border: solid 1px var(--grey-500);
    }

    .btn {
      margin-right: 0.7rem;
      img {
        width: 24px;
        height: 24px;
      }
    }

    .btnBold {
      margin-right: 0.7rem;

      img {
        width: 30px;
        height: 30px;
      }
    }
  }

  .editor {
    height: 8rem;
    font-size: 0.9rem;
    padding: 1rem;
    overflow: hidden;
    overflow-y: auto;
    cursor: default;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--grey-300);
      border-radius: 5px;
    }
    &::-webkit-scrollbar-track {
      background-color: var(--grey-50);
      border-radius: 4px;
    }
  }
}
