.feedHistory {
  overflow: hidden;
  height: 50vh;
  border-radius: 6px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.15);
  padding: 0.5rem 0;
  h3 {
    font-size: 16px;
    color: var(--dark-blue);
    margin: 7px 0 7px 0;
    padding-inline: 16px;
  }
  .feedContainer {
    height: 90%;
    overflow: auto;
    .eachContainerHistory {
      margin-inline: 16px;
      padding-block: 7px;
      border-bottom: 0.1px solid var(--dark-blue);
      
      .feedTitle {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          width: 50%;
          text-wrap: nowrap;
          margin-block: 1px;
          font-size: 12px;
          line-height: 20px;
        }
        .datehistory {
          width: 40%;
          margin-block: 1px;
          font-size: 8px;
          text-wrap: nowrap;
          font-weight: bold;
          line-height: 14px;
          
        }
        .arrowClose {
          transform: rotate(-90deg);
          width: max-content;
          transition:  0.2s all;
          img {
            width: 10px;
            height: 10px;
            object-fit: contain;
          }
        }
        .arrowOpen {
          width: max-content;
          transition: 0.2s all;
          img {
            width: 10px;
            height: 10px;
            object-fit: contain;
          }
        }
      }

      .tagContainers {
        cursor: default;
        margin-top: 2px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 2px;
        span {
            font-size: 10px;
        }
        p {
          width: max-content;
          margin: 0;
          padding-block: 1px;
          padding-inline: 4px;
          font-size: 8px;
          border-radius: 10px;
          color: var(--primary-dark);
          background-color: var(--primary-50);
        }
      }
      .tagContainersRemove{
        cursor: default;
        margin-top: 2px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 2px;
        span {
            font-size: 10px;
        }
        p {
          width: max-content;
          margin: 0;
          padding-block: 1px;
          padding-inline: 4px;
          font-size: 8px;
          border-radius: 10px;
          color: var(--warning);
          background-color: var(--light-red);
        }
      }
    }
    .eachContainerHistory:last-child{
      border-bottom: 0;
    }
  }
}

