@mixin inputStyle {
  color: var(--dark-blue);
  border: 0.5px solid var(--dark-grey);
  border-radius: 6px;
  outline: none;
  font-family: "ManropeRegular";
  font-size: 0.9rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  &:focus,
  &:active {
    border-color: var(--primary-100);
    border-width: 2px;
  }

  &::-webkit-input-placeholder {
    color: var(--light-grey);
  }
}

.modal {
  width: 50rem;
  max-height: 43.5rem;
  background-color: var(--background);
}

// .modal::-webkit-scrollbar {
//   width: 0.375rem;
// }
// .modal::-webkit-scrollbar-track {
//   border-radius: 5px;
//   background-color: #d4d3d9;
// }
// .modal::-webkit-scrollbar-thumb {
//   border-radius: 5px;
//   background-color: var(--light-grey);
// }

.container {
  padding: 0 1.5rem;
}

.messageTypeItem {
  padding: 1rem 2.5rem 1rem 1.5rem;
  display: grid;
  grid-template-columns: 27% 70%;
  column-gap: 1.5rem;
  row-gap: 1rem;

  &:last-child {
    padding-bottom: 2rem;
  }

  img {
    justify-self: flex-end;
    align-self: center;
  }

  .typeContainer {
    display: flex;
    align-items: center;

    label {
      margin-left: 1rem;
      font-size: 0.9rem;
      font-family: "ManropeSemiBold";
      line-height: 1.25;
    }
  }

  p {
    margin: 0;
    font-size: 0.875rem;
    text-align: justify;
    line-height: 1.44;
  }
}

.messageExpandable {
  input {
    height: 100%;
    width: 100%;
    @include inputStyle;
    border: none;

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 50px white inset;
    }
  }

  .recipientsContainer {
    border-bottom: 1px solid var(--primary-100);
    padding: 1rem 1.5rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .btnEdit {
    background-image: none;
    background-color: var(--primary-25);
    color: var(--dark-blue);
  }

  .lang {
    padding-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .messageContainer {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;

    .object {
      margin-top: 0.5rem;
      padding: 0.5rem 1.2rem;
      height: 36.8px;
      @include inputStyle;
    }

    .textarea {
      height: 12.5rem;
      margin-top: 1.2rem;
      padding: 1rem 1.2rem 3rem;
      resize: none;
      @include inputStyle;
      line-height: 1.25;
    }
  }

  .pinContainer {
    display: flex;
    align-items: center;
    padding: 1.2rem 1.5rem;

    .pinImage {
      width: 20px;
      height: 20px;
    }

    .pinLabel {
      font-family: "ManropeSemiBold";
      margin-left: 1rem;
      font-size: 0.95rem;
    }
  }
}

.actionContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem 0;
}

.btnWording {
  width: 6rem;
}

.loader {
  width: 30px;
  height: 30px;
  border-width: 6px;
}
