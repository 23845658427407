.card {
  width: 100%;
  max-width: 60rem;
  min-width: 100%;
  white-space: nowrap;
  padding: 1.2rem 2rem;
  border-radius: 6px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.15);
  margin-bottom: 1.5rem;
  background-repeat: no-repeat;
  background-position: right 1rem top 50%;
  background-size: 0.75rem;

  .titleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  h3 {
    margin: 0;
  }

  .customInput {
    height: 25.6px !important;
  }

  .btn {
    margin-left: 0.7rem;
    width: fit-content;
    img {
      width: 25px;
      height: 25px;
    }
  }

  .btnConfirm {
    img {
      width: 19px;
      height: 18px;
    }
  }

  .container {
    width: 100%;
    overflow-x: auto;
    padding: 1rem 0;
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--grey-500);
      border-radius: 4px;
    }
    &::-webkit-scrollbar-track {
      background-color: var(--grey-100);
      border-radius: 4px;
    }
  }

  .contentList {
    .itemCard {
      width: 6.25rem;
      height: 9.25rem;
      display: inline-block;
      text-align: center;
      background-repeat: no-repeat;
      background-size: cover;
      background-size: 100% 100%;
      background-color: var(--neutral-white);
      white-space: normal;

      span {
        font-size: 0.7rem;
      }

      &:hover {
        background-size: 100% 0;
        background-color: var(--neutral-white) !important;
      }

      &:not(:hover) {
        background-size: 100% 100%;
      }

      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }

  .noItem {
    font-size: 0.8rem;
  }

  .loader {
    width: 30px;
    height: 30px;
    border-width: 6px;
  }

  .actionsContainer {
    display: flex;
    align-items: center;
  }

  .langs {
    width: 15rem;
    margin-bottom: 0.5rem;
    padding-top: 0rem;
  }

  @media (max-width: 1535px) {
    width: 45rem !important;
  }

  @media (max-width: 1280px) {
    width: 20rem !important;
  }
}
