.colGridLayout {
  height: 100%;
  display: grid;
  grid-template-columns: 9rem auto;

  @media (max-width: 1110px) {
    grid-template-columns: 7rem auto;
  }
}

.rowGridLayout {
  height: 100%;
  display: grid;
  grid-template-rows: 95% 5%;
  overflow-y: auto;
}

.main {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 0 2.5rem;
  padding-bottom: 1rem;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--grey-500);
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: var(--neutral-white);
    border-radius: 4px;
  }
}

.modal {
  width: 30rem !important;
  padding: 1rem 1rem 2rem;
  text-align: center;
  border-radius: 10px !important;
  z-index: 550 !important;

  h3 {
    color: var(--error);
    font-family: "ManropeExtraBold";
  }
}

.backdrop {
  z-index: 500 !important;
}
