.expPickerView {
  cursor: pointer;
}

.searchInput {
  margin-top: 0.7rem;
  margin-left: 1.2rem;
  width: 95%;
  padding: 0;
  input {
    font-size: 0.75rem;
  }
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .titleContainer {
    display: flex;
    align-items: center;

    .img {
      width: 18px;
      height: 18px;
    }

    label {
      margin-left: 1.5rem;
      font-family: "ManropeSemiBold";
      cursor: pointer;
    }
  }
}

.arrowIcon {
  width: 10px;
  height: 10px;
  object-fit: contain;
}

.pickerView {
  margin: 1rem 2.7rem;

  .pickerCell {
    margin-bottom: 0.3rem;

    img {
      width: 18px;
    }

    label {
      left: 2.5rem;
      font-size: 0.9rem;
      line-height: 1.25;
    }

    .circle {
      left: 2.5rem;
    }
  }
}
