// Container styles
.container {
  background-color: var(--neutral-white);
  border-radius: 6px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.15);
  margin-top: 1rem;
  user-select: none;

  &.dragging {
    cursor: grabbing;
  }
}

// Expandable menu styles
.expMenu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0.75rem;
  padding-left: 1.5rem;
  cursor: pointer;

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .actionBtnsContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.3rem;
  }

  span {
    font-family: "ManropeSemiBold";
    user-select: none;
  }

  &.dragMenu {
    padding-left: 0 !important;
    cursor: grabbing;
  }
}

// Button styles
.btn {
  img {
    width: 13px;
    height: 13px;
  }
}

.actionBtn {
  width: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 28px;
    height: 28px;
  }
}

// Drag button styles
.dragBtnWrapper {
  width: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;

  .dragIcon {
    cursor: grabbing;
    pointer-events: none;
    width: 28px;
    height: 28px;
  }
}

// Disable pointer events
.disable {
  pointer-events: none;
}

// Menu container styles
.menuContainer {
  border-top: 1px solid var(--primary-100);
  padding-bottom: 0.5rem;
}

// Menu styles
.menu {
  padding: 1rem;
  padding-bottom: 0;
  padding-left: 1.5rem;
  max-height: 17rem;
  overflow-y: auto;
}

// Button container styles
.btnContainer {
  border-top: 1px solid var(--primary-100);
  padding: 1rem 0;
  text-align: center;
}

.modifyBtn {
  width: 18rem;
  height: 1.5rem;
  background-image: none;
  background-color: var(--primary-25);
  color: var(--primary-dark);
}

// Picker cell styles
.pickerCell {
  div {
    margin-bottom: 0.7rem;
    font-size: 0.9rem;

    img {
      width: 18px;
    }

    label {
      left: 2.5rem;
    }
  }
}
