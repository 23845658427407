.tableContainer {
  background-color: #fcfcfc; 
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.1); 
}

.table {
  width: 100%; 
  border-collapse: collapse; 
}


.table thead {
  background-color: #e0e0e0; 
}

.table th, .table td {
  padding: 1px; 
  text-align: left; 
}
