.button {
  background-image: none;
  background-color: var(--primary-25);
  justify-self: flex-end;
  width: 4.5rem;
  height: 1.5rem;
  font-size: 0.7rem;
  color: var(--dark-blue);

  &:hover,
  &:active {
    background-image: linear-gradient(106deg, var(--secondary-400), var(--primary-400) 89%);
    color: var(--white);
  }
}
