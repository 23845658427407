// .loaderContainer {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100%;
// }

.loader {
  border: 8px solid var(--grey-100);
  border-top: 8px solid var(--primary-400);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

.position {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
