.backdrop {
  z-index: 250;
}

.modal {
  width: 50rem;
  background-color: var(--background);
  z-index: 300;
  max-height: 43.5rem;
  min-height: 15rem;

  .container {
    padding: 0 1.5rem;

    .menuContainerClassName {
      padding: 1rem 1.5rem;
      max-height: 20rem;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 5px;
        height: 8px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: var(--grey-200);
        border-radius: 4px;
      }
      &::-webkit-scrollbar-track {
        background-color: var(--neutral-white);
        border-radius: 4px;
      }
    }

    .pickerCell {
      label {
        left: 1.7rem;
      }
    }
  }

  .line {
    margin-top: 1rem;
  }

  .actionContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5rem 0;

    button {
      height: 30px;
    }
  }

  .loader {
    width: 30px;
    height: 30px;
    border-width: 6px;
  }
}
