.btn {
  padding: 0.5rem 1.1rem;
  border-radius: 20px;
  color: var(--neutral-white);
  cursor: pointer;
  outline: none;
  font-family: "ManropeRegular";
  font-size: 1rem;
  background-image: linear-gradient(
    111deg,
    var(--secondary-400),
    var(--primary-400) 88%
  );

  img{
    width: 18px;
    height: 20px;
  }
}
