.messageContent {
  height: 100%;
}

.infoSection {
  border-radius: 8px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.15);
  border: solid 1px var(--primary-400);
  background-color: var(--white);
  margin-bottom: 0.25rem;
}

.langList {
  margin-left: 1rem;
}

.contentSection {
  padding: 0.8rem 1rem;
  padding-bottom: 0;
  display: grid;

  p {
    font-size: 0.875rem;
    line-height: 1.71;
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }
}

.infoRow {
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    font-size: 0.75rem;
    line-height: 1.5;
  }

  h3 {
    margin: 0;
    font-family: "ManropeExtraBold";
    font-size: 1.25rem;
  }

  .dot {
    margin: 0 0.5rem;
    width: 0.4rem;
    height: 0.4rem;
  }

  img {
    width: 20px;
    height: 20px;
  }

  .pinned {
    margin-right: 0.5rem;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }
}

.recipients {
  background-color: var(--primary-25);
  width: 5rem;
  font-size: 0.625rem;
  line-height: 1.8;
  text-align: center;
  margin: 0.4rem 0;
}

.actionsContainer {
  margin-top: 3rem;
  margin-bottom: 1.25rem;
  width: 20%;
  justify-self: flex-end;

  .nbOfChars {
    color: var(--grey-800);
    font-size: 0.8rem;
    text-align: end;
    display: block;
    margin-bottom: 0.5rem;
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 1.25rem;

    img {
      width: 34px;
      height: 34px;
    }

    button {
      margin-left: 1rem;
    }
  }
}

.line {
  border-image-source: linear-gradient(
    to right,
    var(--secondary-400),
    var(--primary-400) 89%
  );
  border-width: 0.5px;
}

.customersSection {
  padding: 1.25rem 1rem;
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.15);

  .statusContainer {
    font-size: 0.75rem;
    color: var(--grey-800);
    margin-bottom: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      cursor: pointer;
      margin-right: 1.25rem;
    }

    .searchInput {
      width: 45%;
    }
  }

  .active {
    color: var(--primary-400);
    font-family: "ManropeExtraBold";
    line-height: 1.67;
    letter-spacing: 0.74px;
    opacity: 0.9;
  }
}

.verticalLine {
  width: 0.1rem;
  height: 1.8rem;
  margin: 0 1.5rem;
}

.loader {
  width: 40px;
  height: 40px;
  border-width: 8px;
}

.loaderContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pinLoader {
  width: 20px;
  height: 20px;
  border-width: 4px;
  margin-right: 0.5rem;
}
