.searchInput {
  width: 100%;
  border-radius: 8px;
  border: 0.5px solid var(--grey-600);
  background-color: var(--white);
  display: grid;
  grid-template-columns: 15% 73% 12%;
  align-items: center;
  padding: 0.2rem 0;
  overflow: hidden;

  img {
    margin: auto;
  }

  input {
    width: 100%;
    border: none;
    outline: none;
    padding: 0.4rem 0.5rem;
    padding-right: 0;
    padding-left: 0;
    color: var(--dark-blue);
    font-family: "ManropeRegular";

    &::-webkit-input-placeholder {
      color: var(--grey-500);
    }

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 50px white inset;
    }
  }

  .btn {
    margin: 0;
    width: 100%;
  }
}
