.modal {
  width: 32rem;
  z-index: 400;
  text-align: center;
  padding: 1.5rem;

  h4 {
    margin-top: 0;
    margin-bottom: 2rem;
    font-size: 1.25rem;
    font-weight: normal;
  }

  .row {
    display: grid;
    grid-template-columns: 30% 70%;
    align-items: center;
    text-align: start;
    margin-bottom: 1.5rem;

    label {
      line-height: 1.25;
      color: var(--grey-800);
      font-size: 0.875rem;
    }

    .star {
      color: var(--red);
      margin-left: 0.5rem;
    }
  }

  .pickerView {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  .pickerCell {
    margin-right: 7rem;

    label {
      left: 2rem;
    }
  }

  .customInput {
    text-align: start;
    grid-template-columns: 25% 75%;
  }

  .btnCancel {
    background-image: none;
    border: solid 1px var(--grey-700);
    background-color: var(--white);
    color: var(--grey-800);
    margin-right: 1.5rem;
  }
}

.backdrop {
  z-index: 300;
}
