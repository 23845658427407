.navItem {
  width: 100%;
  margin-bottom: 0.25rem;

  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: var(--primary-200);
    padding: 0.7rem 0;
    outline: none;

    &:focus {
      background-color: var(--primary-dark-active-sidebar);
    }
  }

  span {
    margin-top: 0.3rem;
    font-size: 0.8rem;
  }

  .active {
    border-left: 5px solid var(--primary-100);
    color: var(--primary-100);
    background-color: var(--primary-dark-active-sidebar);
  }

  &:hover {
    background-color: var(--primary-dark-active-sidebar);
  }

  .logout {
    color: var(--primary-400);
    padding: 0.7rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
  }
}
