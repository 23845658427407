.toast {
  border-left: 3px solid var(--secondary-600);
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 3rem;
  left: 55%;
  transform: translate(-50%);
  background-color: var(--white);
  padding: 0.8rem 1.2rem;
  border-radius: 4px;
  box-shadow: 0 1px 5px 0 rgba(46, 49, 54, 0.3);

  span {
    margin-left: 1rem;
    font-size: 0.875rem;
  }

  .btnUndo {
    margin-left: 1.2rem;
    background-image: none;
    background-color: var(--error);
  }
}
