.backdrop {
  z-index: 200;
}

.modal {
  top: 50% !important;
  transform: translateY(-50%) !important;
  width: 40rem;
  border-radius: 8px;
  z-index: 300;

  h4 {
    border-bottom: 1px solid var(--primary-100);
    padding: 1rem 1.5rem;
    margin: 0;
  }

  .searchSortView {
    margin: 1rem;
  }

  .searchInput {
    width: 75%;
  }

  .expButton {
    width: 22%;
    border-color: var(--grey-500);
  }

  .historyList {
    min-height: 10rem;
    max-height: 20rem;
    overflow-y: auto;
    margin: 0 1rem 1rem 1.5rem;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--grey-300);
      border-radius: 5px;
    }
    &::-webkit-scrollbar-track {
      background-color: var(--grey-100);
      border-radius: 4px;
    }
  }

  .actionContainer {
    text-align: center;
    padding: 1rem 0;
  }
}
