  .main {
    // margin-top: 4rem;
  
    .searchSortView {
      width: fit-content;
    }
  
    .expButton {
      margin-left: 0.5rem !important;
    }
  
    .searchInput {
      border: 1px solid var(--grey-300);
    }
  
    .list {
      margin-top: 1rem;
      display: grid;
      column-gap: 1.2rem;
      row-gap: 1.2rem;
      margin: 10px;
    }
    @media (max-width: 1920px) {
      .list {
        grid-template-columns: repeat(5, 1fr);
      }
    }
    @media (max-width: 1600px) {
      .list {
        grid-template-columns: repeat(4, 1fr);
      }
    }
  
    @media (max-width: 1350px) {
      .list {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  
    @media (max-width: 768px) {
      .list {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  
    @media (max-width: 576px) {
      .list {
        grid-template-columns: 1fr;
      }
    }
  
  }
  
  
  .scrollContainer {
    display: flex;
    flex-direction: column;
    background-color: #fdfdfd; 
    overflow-y: auto; 
    padding: 20px; 
    margin-top: 20px;
    border-radius: 8px; 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .scrollContent {
    flex: 1; 
    overflow-y: auto;
  }
  
  @media (max-width: 1920px) {
    .scrollContainer {
      max-height: 707px; 
    }
  }
  
  @media (max-width: 1700px) {
    .scrollContainer {
      max-height: 700px; 
    }
  }
  
  @media (max-width: 1550px) {
    .scrollContainer {
      max-height: 620px; 
    }
  }
  
  @media (max-width: 1500px) {
    .scrollContainer {
      max-height: 610px; 
    }
  }
  
  
  @media (max-width: 992px) {
    .scrollContainer {
      max-height: 590px; 
    }
  }
  
  
  .scrollFooter {
    padding: 5px;
  }
  
  
  .scrollHeader {
    display: flex;
    justify-content: space-between; 
    align-items: center; 
    padding: 0px 20px 6px 20px;
  }
  
  .totalItems {
    font-size: 14px;
    color: #3a4455;
    margin: 0;
  }
  
  .headerContainer {
    display: flex;              
    align-items: center;        
    justify-content: space-between;                
  }
  
  .actionsContainer {
    display: flex;
    align-items: center;
    width: 83px;
    height: 41.5px;
    padding: 3.1px 3px 3.1px 10px;
    border-radius: 28.5px;
    background-color: #f1ecfb;
  
    .btnActive {
      background-color: var(--neutral-white);
      border-radius: 20px;
      padding: 7px;
    }
  }
  