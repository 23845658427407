.page {
  height: 100%;
}

.cardMenu {
  padding-top: 0 !important;
}

.list {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 1.5rem;
  row-gap: 1.25rem;

  @media (min-width: 1750px) {
    grid-template-columns: repeat(7, 1fr);
  }

  @media (max-width: 1536px) {
    grid-template-columns: repeat(6, 1fr);
  }

  @media (max-width: 1440px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media (max-width: 1200px) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.itemCard {
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 100% 100%;
  background-color: var(--neutral-white);

  &:hover {
    background-size: 100% 0;
    background-color: var(--neutral-white) !important;
  }

  &:not(:hover) {
    background-size: 100% 100%;
  }
}

.btnsContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.btn {
  margin-left: 1.5rem;
  img {
    width: 40px;
    height: 40px;
  }
}

.bold {
  font-family: "ManropeExtraBold";
}

.loaderContainer {
  height: 20rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .loader {
    width: 30px;
    height: 1.8rem;
    border-width: 6px;
  }
}
