.pickerViewContainer {
  display: grid;
  grid-template-columns: 30% 70%;
  margin-bottom: 1.5rem;
}

.pickerView {
  grid-column-start: 2;
}

.pickerCell {
  label {
    left: 1.5rem;
  }
}
