.userCard {
  // width: 19.5%;
  border-radius: 8px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.15);
  padding: 1.2rem 1rem 1rem;
  position: relative;
  min-height: 7rem;

  .star {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }

  .row {
    display: grid;
    grid-template-columns: 35% 65%;
    font-size: 0.95rem;

    label {
      text-align: right;
    }

    span {
      margin-left: 1rem;
    }
  }

  .bold {
    font-family: "ManropeExtraBold";
  }

  .actionContainer {
    text-align: center;
    padding: 1rem 0 0.5rem;

    .btn {
      background-image: none;
      background-color: var(--primary-25);
      width: 3.6rem;
      height: 1.7rem;
      color: var(--dark-blue);
    }
  }
}
