.expList {
  background-color: var(--grey-100);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.6rem 1rem;
  border-radius: 8px;
  margin-top: 1.5rem;
  margin-bottom: 0.25rem;
  cursor: pointer;
  user-select: none;

  .titleContainer {
    display: flex;
    align-items: center;

    span {
      line-height: 1.25;
      font-size: 0.9rem;
    }

    .dot {
      width: 0.25rem;
      height: 0.25rem;
      margin: 0.5rem;
    }
  }

  img {
    width: 12px;
    height: 12px;
  }
}

.listItem {
  background-color: var(--grey-100);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.6rem 0.4rem 0.65rem 1rem;
  margin-bottom: 0.25rem;

  span {
    font-size: 0.875rem;
  }

  img {
    width: 17px;
    height: 17px;
  }
}

.hiddenNumber {
  background-color: var(--primary-25);
  text-align: center;
  border-radius: 17.5px;
  line-height: 1.22;
  color: var(--primary-dark);
  width: 4.5rem;
  padding: 0.3rem 0;
  margin: auto;
  cursor: pointer;
}
